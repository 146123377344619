"use strict";

(function glExtendDetails() {
  "use strict";

  var opts;
  gol.details = {
    init: function init(options) {
      //console.log("details.init() called");

      // build main options before element iteration
      opts = $.extend({}, gol.details.init.defaults, options);

      // Support the metadata-plugin
      var o = $.meta ? $.extend({}, opts, $(this).data()) : opts;
      if (o.menu_on) {
        this.clickMenu.init();
      }
      this.corona.init();
      this.setHandlers();
      this.initCopyToClipboard();
      $("#txtCategory").suggest("/redak/suggestCategory/textOnly/", {
        onSelect: function onSelect() {
          this.focus();
          this.value = this.value;
        }
      });

      // Initialize knowledge-teaser
      var $kensecondary = $(".ken-teaser__secondary");
      $(".ken-teaser__primarily").on("click", function () {
        if ($kensecondary.is(":visible")) {
          trackEvent("close-infos", "ken-teaser");
        } else {
          trackEvent("open-infos", "ken-teaser");
        }
        $kensecondary.slideToggle(300);
        $(".ken-teaser__primarily-btn").toggleClass("ken-teaser__primarily-btn--active");
      });

      // set links without using a href via js (e.g. filter reviews when clicking on stars statistics line)
      $(".jslink[data-href]").on("click", function (e) {
        var $this = $(this);
        var src = $this.data("href");
        $this.css("cursor", "wait");
        location.href = src;
      });

      // close deepInfo to be able to show it in a pop-up 
      var htmldeepinfos = $(".deepinfos").html();
      $("#deepinfos").append(htmldeepinfos);

      // shore more button and fading only if info is too large
      var nDetailsHeight = $(".loc-infoboxes__details .deepinfos").height();
      var nAvailableHeight = $(".loc-infoboxes__details").height() - 40;
      //console.log("nDetailsHeight =", nDetailsHeight, "| nAvailableHeight =", nAvailableHeight);
      if (nDetailsHeight > nAvailableHeight) {
        //console.log("details zu viel - blende Button ein");
        $(".loc-infoboxes__more-details, .deepinfos__fadeout").css("visibility", "visible").show();
      }
    },
    setHandlers: function setHandlers() {
      $(document).on("click", ".gplusone_share_button", function () {
        gol.trackEvent("postReviewToGooglePlus");
      });

      /**********************************************/
      /***               AutoSave                 ***/
      /***   (only for users that are logged in   ***/
      /***            and for drafts)             ***/
      var autosaveBool = true;
      if (gol.local.data.isValidUser === false) {
        autosaveBool = false;
      }

      // initialize instantreview
      if (opts.hasInstantReview) {
        var optsAS = {
          autosave: autosaveBool,
          UserHasIncentiveAlready: opts.UserHasIncentiveAlready
        };
        gol.instantreview.init(optsAS);
      }
      if (gol.local.data.isValidUser === true) {
        $("body").on("click", ".deletebool", function () {
          console.log("deletebool clicked");
          $("#ddBooleanObjectId").text($(this).data("id"));
          //gol.dialog.open("Wirklich löschen?", "/?height=350&amp;width=350&amp;modal=true", $("#confirmDeleteBooleanWrapper").html());
          $("#confirmDeleteBooleanWrapper").foundation("open");
          return false;
        });
      }
      if (opts.hasTentativeBox) {
        this.tentativeBox.init();
      }
    },
    initCopyToClipboard: function initCopyToClipboard() {
      // Share page via clipboard or email 
      var pageUrl = encodeURIComponent(location.href);
      var pageTitle = encodeURIComponent(document.title);
      $("#js-send-email").attr("href", "mailto:name@domain.com?&subject=" + pageTitle + "&body=" + pageUrl);
      $(".js-copyurl").attr("href", "#dummy-anchor");
      $(".js-copyurl").on("click", function (evt) {
        //console.log("inside onlick");
        evt.preventDefault();
        copyTextToClipboard(location.href);
      });
      function copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(function () {
          copyTextSuccess();
        }, function (err) {
          console.error("Async: Could not copy text: ", err);
        });
      }
      function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.setAttribute("style", "border: none; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;");
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          var successful = document.execCommand("copy");
          var msg = successful ? "successful" : "unsuccessful";
          //console.log("Fallback: Copying text command was " + msg);
          if (msg === "successful") {
            copyTestSuccess();
          }
        } catch (err) {
          console.error("Fallback: Oops, unable to copy", err);
        }
        document.body.removeChild(textArea);
      }
      function copyTextSuccess() {
        $(".js-copyurl").text("Link wurde kopiert");
        setTimeout(function () {
          $(".js-copyurl").text("Link kopieren");
        }, 1500);
      }
    },
    corona: {
      init: function init() {
        //console.log("corona.init() called");

        $(".js-infobox-corona .infobox__c2a").on("click", function () {
          $(".js-infobox-corona__c2a, .js-infobox-corona__btn-list", ".js-infobox-corona").hide();
          $(".js-infobox-corona__hiddensec", ".js-infobox-corona").show();
          $(".js-infobox-textarea-services").focus();
        });
        $(".js-infobox-corona__submitcomment").on("click", function () {});

        /*var booSwitchValServicesChecked;
        $(".js-infobox-switch-services .switch-input").change(function(){				
        	booSwitchValServicesChecked = $(this).is(":checked");
        	//console.log("Wert hat sich geändert", booSwitchValServicesChecked);
        	
        	if(booSwitchValServicesChecked){
        		$(".js-infobox-textarea-services").slideDown(80).select().focus();
        	} else {
        		$(".js-infobox-textarea-services").slideUp(150);
        	}
        });*/

        /*
        $(".infobox__frm-corona").validate({
        	submitHandler: function(form) {
        		$(".js-infobox-corona__submitcomment").attr("disabled", "disabled");
        		
        		// Replace normal form-submit-behavior with ajax-form-submit
        		var options = {
        			success: function(returnValue) {
        				if(returnValue.status === "ok"){
        					
        					$(".js-infobox-corona").slideUp(800, function(){
        						$(".js-infobox-corona-success").slideDown(300);
        					});
        				} else {
        					$(".js-infobox-corona__submitcomment").after("<div class="infobox__error"><strong>Es gab einen Fehler:</strong><br/>" + returnValue.message + "</div>");
        					setTimeout(function(){
        						$(".infobox__error").hide("1000");
        					}, 3000);
        					$(".js-infobox-corona__submitcomment").removeAttr("disabled");
        				}
        			},
        			error: function(jqXHR, textStatus, errorThrown) {
        				gol.gen.alert(errorThrown);
        				$(".js-infobox-corona__submitcomment").removeAttr("disabled");
        			}
        		};
        				// Submit form
        		$(form).ajaxSubmit(options);
        	},				
        	messages: {
        		//infoText: {required: "<br class="clear error-msg"/>Welche Zusatzleistungen bietet das Unternehmen an?",
        	},
        	rules: {
        		//infoText: "required"
        	}
        });
        */
      }
    },
    tentativeBox: {
      init: function init() {
        $(".mloc-vote").on("click", function () {
          var $this = $(this);
          var loaderClass = "a-like-loading";
          var startUpOffClass = "vv-up";
          var startDownOffClass = "vv-down";
          var upOnClass = "vv-up-selected";
          var upOffClass = "vv-up-notselected";
          var downOnClass = "vv-down-selected";
          var downOffClass = "vv-down-notselected";
          var votedThanksClass = "mloc-vote-thanks";
          var upClass = "mloc-vote-up";
          var downClass = "mloc-vote-down";
          var subscriberId = $this.data("subscriberid");
          var userId = $this.data("userid");
          var voting = $this.data("voting");
          var isVoteUp = $this.hasClass(upClass);
          var isVoteDown = $this.hasClass(downClass);
          var isTurnedOn = $this.hasClass(startUpOffClass) || $this.hasClass(startDownOffClass) || $this.hasClass(upOffClass) || $this.hasClass(downOffClass);
          var isTurnedOff = $this.hasClass(upOnClass) || $this.hasClass(downOnClass);
          var voteHref = "/mlocvoting/" + subscriberId + "/" + userId + "/" + voting + "/" + isTurnedOn + "/";
          $this.css("opacity", "0.5").addClass(loaderClass);
          $.get(voteHref, function (json) {
            if (json !== undefined) {
              var votingJson = json.voting;
              if (votingJson === 1 || votingJson === -1) {
                $("." + votedThanksClass).remove();
                if (isTurnedOn) {
                  if (isVoteUp) {
                    $this.removeClass(startUpOffClass).removeClass(upOffClass).addClass(upOnClass);
                    $("." + downClass).removeClass(downOnClass).addClass(downOffClass);
                  } else if (isVoteDown) {
                    $this.removeClass(startDownOffClass).removeClass(downOffClass).addClass(downOnClass);
                    $("." + upClass).removeClass(upOnClass).addClass(upOffClass);
                  }
                  $(".vote-verify-thumbs").after("<div class='" + votedThanksClass + "'>Vielen Dank!</div>");
                  $("." + votedThanksClass).fadeOut(3000);
                } else if (isTurnedOff) {
                  $("." + upClass).removeClass(upOnClass).removeClass(upOffClass).addClass(startUpOffClass);
                  $("." + downClass).removeClass(downOnClass).removeClass(downOffClass).addClass(startDownOffClass);
                }
              }
            }
          }).fail(function () {
            //gol.gen.alert();
          }).always(function () {
            $this.css("opacity", "").removeClass(loaderClass);
          });
          return false;
        });
      }
    },
    clickMenu: {
      init: function init() {
        // http://davidwalsh.name/twitter-dropdown-jquery

        /* for keeping track of what's "open" */
        var activeClass = "dropdown-active",
          showingDropdown,
          showingMenu,
          showingParent;
        /* hides the current menu */
        var hideMenu = function hideMenu() {
          if (showingDropdown) {
            showingDropdown.removeClass(activeClass);
            showingMenu.hide();
          }
        };
        $(".dropdownMenu").each(function () {
          /* track elements: menu, parent */
          var dropdown = $(this);
          var menu = dropdown.next("div.dropdownMenu-menu"),
            parent = dropdown.parent();
          /* function that shows THIS menu */
          var showMenu = function showMenu() {
            hideMenu();
            showingDropdown = dropdown.addClass("dropdown-active");
            showingMenu = menu.show();
            showingParent = parent;
            gol.trackEvent("showClickMenu");
          };
          /* function to show menu when clicked */
          dropdown.bind("click", function (e) {
            if (e) {
              e.stopPropagation();
            }
            if (e) {
              e.preventDefault();
            }
            showMenu();
          });
          /* function to show menu when someone tabs to the box */
          dropdown.bind("focus", function () {
            showMenu();
          });

          /* function to hide menu when someone clicks on the menu points */
          menu.on("click", function () {
            hideMenu();
          });
        });

        /* hide when clicked outside */
        $(document.body).on("click", function (e) {
          if (showingParent) {
            var parentElement = showingParent[0];
            if (!$.contains(parentElement, e.target) || parentElement !== e.target) {
              hideMenu();
            }
          }
        });
      }
    },
    removeDeepData: function removeDeepData(ddtId, refid, type) {
      $.post("/deepdata/delete/", {
        ddtId: ddtId,
        rid: refid,
        type: type
      }, function () {}).done(function () {
        gol.dialog.hideAndReload();
      }).fail(function (xhr, textStatus, errorThrown) {
        gol.gen.alert(errorThrown);
      }).always(function () {});
      return false;
    },
    reviewTeaser: {
      init: function init() {
        // open a foundation reveal popup window after 30 sec
        // but not for owner and only if user didn't already write a review for this location		
        if (typeof Storage !== "undefined") {
          // only show review reveal if it was not already shown in this session
          if (sessionStorage.getItem("gl-reviewWindowShown") !== "true") {
            setTimeout(function () {
              // open reveal only if no other reveal is opened that would be overridden
              // and if user is not currently writing a review with instant review box (isInReviewMode=true)
              if ($(".reveal:visible").length > 0 === false && !gol.details.reviewTeaser.wasInReviewMode()) {
                $("#rate-location-window-stars").foundation().foundation("open");
                gol.trackEvent("openReveal", "reviewTeaser");
                sessionStorage.setItem("gl-reviewWindowShown", "true");
                // initialize second window to be able to open this reveal
                $("#features-popup").foundation();

                // only for popups with ratingstars
                $("#rate-location-window-stars .stars_reviewteaser input[type=radio]").rating({
                  callback: function callback() {
                    location.href = gol.local.data.sRootPath + "/writeReview/" + gol.local.data.glIdLng + "/?rating=" + this.value + "&backUrl=" + gol.local.data.encActualUrl;
                  }
                });
                setTimeout(function () {
                  gol.details.animateStars("blink");
                }, 10);
              }
            }, 30000);
          }
        } /*else {
          console.log("kein Storage oder gerade am Bewertung schreiben");
          }*/
      },
      wasInReviewMode: function wasInReviewMode() {
        //console.log("wasInReviewMode:", gol.wasInReviewMode);
        // isInReviewMode is set in gl.extent-instantreview.js
        return gol.wasInReviewMode;
      }
    },
    animateStars: function animateStars(sType) {
      var starsInterval, starsIntervalIndex, starsRevDir, $star5;
      switch (sType) {
        case "upanddown":
          starsIntervalIndex = 1;
          starsRevDir = false;
          starsInterval = setInterval(function () {
            $(".js-stars_animate .star-rating:nth-child(" + starsIntervalIndex + ")").mouseover();
            //console.log("interval(", starsIntervalIndex,")");

            if (starsRevDir) {
              if (starsIntervalIndex-- < 2) {
                clearInterval(starsInterval);
                $(".stars_reviewpage .star-rating:nth-child(2)").mouseout();
              }
            } else {
              if (++starsIntervalIndex > 8) {
                starsRevDir = true;
              }
            }
          }, 100);
          break;
        case "blink":
          $star5 = $(".js-stars_animate .star-rating:nth-child(6)");
          starsIntervalIndex = 1;
          starsInterval = setInterval(function () {
            $star5.mouseover();
            //console.log("interval(", starsIntervalIndex,")");

            setTimeout(function () {
              $star5.mouseout();
            }, 500);
            if (starsIntervalIndex++ > 1) {
              clearInterval(starsInterval);
            }
          }, 700);
      }
    }
  };
  gol.details.init.defaults = {
    menu_on: false,
    hasInstantReview: false
  };
})(jQuery);

/*
function onLoadPlusoneClientCallback() {
	"use strict";
	console.log("plusone loaded");
}*/