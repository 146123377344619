"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
(function (factory) {
  if (typeof define === 'function' && define.amd) {
    define(['jquery'], factory);
  } else if ((typeof module === "undefined" ? "undefined" : _typeof(module)) === 'object' && _typeof(module.exports) === 'object') {
    factory(require('jquery'));
  } else {
    factory(jQuery);
  }
})(function (jQuery) {
  // German
  jQuery.timeago.settings.strings = {
    prefixAgo: "vor",
    prefixFromNow: "in",
    suffixAgo: "",
    suffixFromNow: "",
    inPast: "jetzt gleich",
    seconds: "wenigen Sekunden",
    minute: "etwa einer Minute",
    minutes: "%d Minuten",
    hour: "etwa einer Stunde",
    hours: "%d Stunden",
    day: "etwa einem Tag",
    days: "%d Tagen",
    month: "etwa einem Monat",
    months: "%d Monaten",
    year: "etwa einem Jahr",
    years: "%d Jahren"
  };
});