"use strict";

// gl.storage.js
(function glStorage() {
  "use strict";

  gol.storage = {
    init: function init() {
      //console.log("gol.storage.init()");

      this.cookiesAvailable = false;
      this.storageAvailable = false;

      //cookie test
      try {
        var nowCookie = new Date().getTime().toString();
        $.cookie("glCookieHealthCheck", nowCookie);
        var testCookie = $.cookie("glCookieHealthCheck");
        if (testCookie !== undefined && testCookie !== null && testCookie === nowCookie) {
          this.cookiesAvailable = true;
        }
        $.removeCookie("glCookieHealthCheck"); // delete after test to Minimize Request Size (Page Speed Best Practice)
      } catch (e) {
        //console.log("cookies not available, error="+e);
      }

      //localStorage test
      try {
        if ("localStorage" in window && window.localStorage !== null) {
          this.storageAvailable = true;

          //console.log("localStorage available");
        }
      } catch (e1) {
        this.storageAvailable = false;
        //console.log("localStorage not available, error="+e1);
      }
      this.selectedCity.init();

      //console.log("cookiesAvailable="+this.cookiesAvailable);
      //console.log("storageAvailable="+this.storageAvailable);
    },
    areCookiesAvailable: function areCookiesAvailable() {
      return this.cookiesAvailable;
    },
    isStorageAvailable: function isStorageAvailable() {
      return this.storageAvailable;
    },
    clear: function clear(preserveUserInfo) {
      //console.log("gol.storage.clear()");

      if (this.storageAvailable) {
        var userInfo = localStorage.getItem("golocal.userInfo");

        // Remove everything from storage besides valid reviews

        for (var key in localStorage) {
          // check for Michis Vodoo-Stuff
          if (/^(gol.)/.test(key)) {
            localStorage.removeItem(key);
            // check for Victors crazy review things
          } else if (/^(review_)/.test(key)) {
            var reviewObjectFromStorage = localStorage.getItem(key),
              review = JSON.parse(reviewObjectFromStorage);
            if (reviewObjectFromStorage !== undefined && reviewObjectFromStorage !== null && review.createts !== undefined && review.createts !== null && typeof review.createts === "number" && new Date().getTime() > review.createts + 86400 * 1000) {
              localStorage.removeItem(key);
            }
          }
        }

        // Oli: commented out because it just removes everything 
        // and destroys needed functionality for delayed review teaser
        //sessionStorage.clear();

        if (preserveUserInfo !== undefined && preserveUserInfo && userInfo !== undefined && userInfo !== null && userInfo.length > 10) {
          localStorage.setItem("golocal.userInfo", userInfo);
        }
      }
    },
    review: function review() {
      //console.log("gol.storage.review()");

      if (!this.cookiesAvailable) {
        if (this.storageAvailable) {
          var userInfo = localStorage.getItem("golocal.userInfo");
          if (userInfo !== undefined && userInfo !== null && userInfo.length > 10) {
            var reloadLocation = window.location.href;
            if (reloadLocation.indexOf("userInfo=") === -1) {
              sessionStorage.setItem("golocal.storageReloadUrl", reloadLocation);
              //console.log("stored storageReloadUrl="+reloadLocation);
              gol.gen.reload(reloadLocation + "&userInfo=" + encodeURIComponent(userInfo));
            } else {
              $("#frm_review").prepend("<input type='hidden' id='userInfo' name='userInfo' value='" + userInfo + "' />");
            }
          }
        } else {
          var $cookieErrorMessageBox = $("#cookieErrorMessageBox");
          var html = "<div class='infoMsg infoMsg_error secondary callout'><div class='bold'><span class='bold'>Cookies erforderlich</span> " + "Die Cookies sind in diesem Browser nicht aktiviert. Um die Bewertungsfunktion vollständig nutzen zu können, müssen die" + " Sicherheitseinstellungen geändert werden. Eine <a target='_blank' onclick='gol.trackEvent('CookieSupportSite');' " + "rel='nofollow' href='http://support.google.com/accounts/bin/answer.py?hl=de&answer=61416'>ausführliche Anleitung</a>" + " von Google unterstützt dabei. Eine Bewertung kann allerdings trotzdem abgegeben werden.</div></div>";
          $cookieErrorMessageBox.append(html);
          $cookieErrorMessageBox.show();
        }
      }
    },
    saveReview2Storage: function saveReview2Storage(storageKey, reviewText) {
      if (this.storageAvailable && reviewText !== undefined && reviewText !== null && reviewText !== "") {
        var review = {
          "text": reviewText,
          "createts": new Date().getTime()
        };
        localStorage.setItem(storageKey, JSON.stringify(review));
        //console.log("Stored: " + reviewText+ " for key " + storageKey);
      }
    },
    loadReviewFromStorage: function loadReviewFromStorage(storageKey) {
      if (this.storageAvailable) {
        var reviewObjectFromStorage = localStorage.getItem(storageKey);
        if (reviewObjectFromStorage !== undefined && reviewObjectFromStorage !== null) {
          // It is expected that invalid reviews (createts) are deleted during storage clearence
          var review = JSON.parse(reviewObjectFromStorage);
          //console.log("Fetched from storage: " + review.text);
          return review;
        }
      }
    },
    removeReviewFromStorage: function removeReviewFromStorage(storageKey) {
      if (this.storageAvailable) {
        //console.log("Clearing storage for key " + storageKey);
        localStorage.removeItem(storageKey);
      }
    },
    user: function user(userInfo) {
      //console.log("gol.storage.user()");

      if (this.storageAvailable && userInfo !== undefined && userInfo !== null && userInfo.length > 10) {
        localStorage.setItem("golocal.userInfo", userInfo);
        //console.log("stored userInfo="+userInfo);
      }
    },
    session: function session() {
      //console.log("gol.storage.session()");

      var $sessionInfo = $("#sessionInfo");
      var $sessionStorage = $("#sessionStorage");
      if ($sessionInfo.size() > 0 && $sessionStorage.size() > 0) {
        $sessionInfo.val($sessionStorage.val());
        //console.log("stored sessionInfo="+$sessionInfo.val());
      }
      var $coopRef = $("#coopRef");
      var $coopStorage = $("#coopStorage");
      if ($coopRef.size() > 0 && $coopStorage.size() > 0) {
        $coopRef.val($coopStorage.val());
      }
    },
    reload: function reload() {
      //console.log("gol.storage.reload()");
      var storageReload = false;
      if (!this.cookiesAvailable) {
        if (this.storageAvailable) {
          var storageReloadUrl = sessionStorage.getItem("golocal.storageReloadUrl");
          //console.log("storageReloadUrl="+storageReloadUrl);

          if (storageReloadUrl !== undefined && storageReloadUrl !== null && storageReloadUrl.length > 0) {
            gol.storage.clear();
            storageReload = true;
            gol.gen.reload(storageReloadUrl);
          }
        }
      }

      //console.log("location="+location);

      if (!storageReload) {
        // Reload from Server without Browser-Cache
        // kein gol.loader.show() wegen Koop-Frames
        location.reload(true);
      }
    },
    survey: {
      init: function init(nSurveyId) {
        //console.log("survey.init(", nSurveyId, ") called");
        // get stored array of hidden surveys
        this.getStatus();
        // hide or show survey
        this.checkSurvey(nSurveyId);

        //console.log("arrHiddenSurveys =", this.arrHiddenSurveys);
      },
      checkSurvey: function checkSurvey(nSurveyId) {
        //console.log("checkSurveyVis(", nSurveyId, ") called");
        //console.log("this.arrHiddenSurveys = ", this.arrHiddenSurveys);
        var sHideSurveyId = "";
        for (var a in this.arrHiddenSurveys) {
          if (this.arrHiddenSurveys[a] === nSurveyId) {
            //console.log("ID '", nSurveyId, "' gefunden");
            this.hideSurvey(nSurveyId);
            sHideSurveyId = nSurveyId;
            break;
          }
        }
        if (sHideSurveyId === "") {
          this.showSurvey(nSurveyId);
        }
      },
      showSurvey: function showSurvey(nSurveyId) {
        $(".survey-" + nSurveyId).show();
        //console.log("showSurvey ", nSurveyId);
        //console.log("arrHiddenSurveys = ", this.arrHiddenSurveys);
      },
      hideSurvey: function hideSurvey(nSurveyId) {
        $(".survey-" + nSurveyId).slideUp();
        //console.log("hideSurvey ", nSurveyId);
      },
      closeSurvey: function closeSurvey(nSurveyId) {
        this.hideSurvey(nSurveyId);
        this.arrHiddenSurveys.push(nSurveyId);
        this.storeStatus();
        //console.log("survey '", nSurveyId, "' closed");
        //console.log("arrHiddenSurveys = ", this.arrHiddenSurveys);
      },
      recoverSurvey: function recoverSurvey(nSurveyId) {
        var arrHiddenSurveys = this.arrHiddenSurveys;
        this.arrHiddenSurveys = $.grep(arrHiddenSurveys, function (n) {
          return n !== nSurveyId;
        });
        this.storeStatus();
        this.arrHiddenSurveys = this.arrHiddenSurveys.splice($.inArray(nSurveyId, this.arrHiddenSurveys), 1);
        //console.log("survey '", nSurveyId, "' recovered");
        //console.log("arrHiddenSurveys nachher = ", this.arrHiddenSurveys);
      },
      storeStatus: function storeStatus() {
        localStorage.setItem("arrHiddenSurveys", JSON.stringify(this.arrHiddenSurveys));
      },
      getStatus: function getStatus() {
        this.arrHiddenSurveys = JSON.parse(localStorage.getItem("arrHiddenSurveys")) || [];
      },
      clearStorage: function clearStorage() {
        localStorage.clear("arrayHiddenSurveys");
        //console.log("Storage cleared");
      }
    },
    selectedCity: {
      // function to prevent multiple cities selection from being shown again and again
      // e.g. Cafe in Bamberg. When you selected Bamberg the layer was shown endless
      init: function init() {
        this.setHandlers();
      },
      setHandlers: function setHandlers() {
        var oThis = this;
        $(".js-searchInfolayer__a", "#searchInfolayer").on("click", function () {
          oThis.storeUsersCity(this.text);
        });
      },
      storeUsersCity: function storeUsersCity(sCity) {
        if (gol.storage.storageAvailable) {
          localStorage.setItem("gl-userCity", sCity);
        }
      },
      checkLayerVis: function checkLayerVis() {
        //console.log("checkLayerVis() called");
        // only show layer if current searchterm of city is not stored value
        if (!this.getStoredCityIsUsersCity()) {
          this.showLayer();
        }
      },
      getStoredCity: function getStoredCity() {
        if (gol.storage.storageAvailable) {
          //console.log("getStoredCity:", localStorage.getItem("gl-userCity"));
          return localStorage.getItem("gl-userCity");
        } else {
          return false;
        }
      },
      getSearchVal: function getSearchVal() {
        // return value of value in city searchfield
        return $("#txtLoc").val();
      },
      getStoredCityIsUsersCity: function getStoredCityIsUsersCity() {
        //console.log("getStoredCityIsUsersCity: getSearchVal:", this.getSearchVal(), "getStoredCity:", this.getStoredCity());
        var sSearchVal = this.getSearchVal();
        sSearchVal = sSearchVal ? sSearchVal.toLowerCase() : "";
        var sStoredCity = this.getStoredCity();
        sStoredCity = sStoredCity ? sStoredCity.toLowerCase() : "";
        // returns true if stored usercity is value from entered city searchfield
        return sSearchVal === sStoredCity;
      },
      showLayer: function showLayer() {
        $("#searchInfolayer").fadeIn();
      }
    }
  };
})(jQuery);
gol.storage.init();