"use strict";

//
// create closure
//
(function glAssignMedia() {
  /* ***************************************/
  /* *         plugin definition         ***/
  /* ***************************************/
  "use strict";

  var opts, $lastMediaHolder, moveDraggableToPool, removeDraggableFromMediaHolder, getMediaHolderFromDraggable, getHolderIsEmpty, checkVisabilityMediaHolder;

  // Begin fn.glAssignMedia
  jQuery.fn.glAssignMedia = function (options) {
    //console.log("$.glAssignMedia() called");

    // build main options before element iteration
    opts = $.extend({}, $.fn.glAssignMedia.defaults, options);
    return this.each(function () {
      var $this = $(this);

      // Support the metadata-plugin
      //var o = $.meta ? $.extend({}, opts, $this.data()) : opts;

      $this.draggable({
        appendTo: "body",
        helper: "clone",
        opacity: 0.7,
        zIndex: 1000,
        start: function start() {
          // console.log("dragging start(", ev, ", ", ui, ") called");
          // store media holder from which element was dragged
          // for later check if element was the last one in it
          $lastMediaHolder = getMediaHolderFromDraggable($(this));
          // console.log("$lastMediaHolder = ", $lastMediaHolder);
        }
      });
    });
  }; // End fn.glAssignMedia

  /* ***************************************/
  /* *         plugin defaults           ***/
  /* ***************************************/

  $.fn.glAssignMedia.defaults = {};
  $.fn.glAssignMedia.assignDroppable = function () {
    $(".locList .listEntry").droppable({
      accept: ".willBeAccepted",
      activeClass: "droppable-active",
      hoverClass: "droppable-hover",
      drop: function drop(ev, ui) {
        //console.log("Foto soll hinzugefügt werden. drop(", ev, ",",ui, ") called");

        if ($(".newAssignedPhotos", $(this)).length > 0) {
          // use special div if available to append draggable
          $(".newAssignedPhotos", $(this)).show().append(ui.draggable);
        } else {
          // otherwise add after marker-element
          $(this).find(".marker").after(ui.draggable);
        }

        // Hide mediaholder if empty
        checkVisabilityMediaHolder($lastMediaHolder);
        var subscriberId, sLocUrl;

        // get subscriber id either from title in locationList entry or from url in details

        // My Reviews Page
        if ($(".reviewList__locinfo-wrap", $(this)).length > 0) {
          // my reviews page
          sLocUrl = $(".js_reviewList__loctitle", $(this)).attr("href");
          subscriberId = sLocUrl.substring(sLocUrl.lastIndexOf("-") + 1);
        } else if ($(".title a", $(this)).length > 0) {
          // location list --> extract subscriber id from header
          var sLocUrl = $(".title", $(this)).find("a").attr("href");
          subscriberId = sLocUrl.substring(sLocUrl.lastIndexOf("-") + 1);
        } else {
          // details page --> use url of location to extract subscriber id
          //var subscriberId = location.href.substring(location.href.lastIndexOf("-")+1);
          //because of ajax request url is no longer useful to extract subscriberId
          subscriberId = $("#subscriberId").text();
        }

        // extract image id from draggable
        var queryString = ui.draggable.find("a").attr("href").replace(/.*?\?/, "");
        var sImageIdFromUrl = queryString.replace(/.*pid\=/, "");
        sImageIdFromUrl = sImageIdFromUrl.replace(/\&.*$/, "");
        var sUrl = "/foto/assign/" + sImageIdFromUrl + "/" + subscriberId;

        // store new image to the location
        $.get(sUrl, function (data, textStatus) {
          if (textStatus === "success") {
            //console.log("Data successfully loaded:", data, "textStatus:",textStatus);

            gol.trackEvent("assignMedia", "photoBox");
          } else {
            gol.gen.alert(textStatus);
          }
        });

        // trigger mouseout-handler of floated section to activate floating again
        $("div.floatedSection").mouseout();
      }
    });
  };
  $.fn.glAssignMedia.assignDroppable();

  /* ***************************************/
  /* *         private functions         ***/
  /* ***************************************/
  $("#btnRemovePhoto").droppable({
    accept: ".willBeAccepted",
    activeClass: "droppable-active",
    hoverClass: "droppable-hover",
    drop: function drop(ev, ui) {
      //console.log("Foto wurde entfernt");

      // remove droppable from mediaHolder
      // and update visibility of mediaHolder
      removeDraggableFromMediaHolder(ui.draggable);

      // extract image id from draggable
      var queryString = ui.draggable.find("a").attr("href").replace(/.*?\?/, "");
      var sImageIdFromUrl = queryString.replace(/.*pid\=/, "");
      sImageIdFromUrl = sImageIdFromUrl.replace(/\&.*$/, "");

      // delete image from golocal
      var sUrl = "/foto/delete/" + sImageIdFromUrl + "/";
      $.get(sUrl, function () {}).done(function () {
        //console.log("Data successfully loaded:", data, "textStatus:",textStatus);
      }).fail(function (jqXHR, textStatus, errorThrown) {
        gol.gen.alert(errorThrown);
      }).always(function () {});

      // Set focus back to body and fire scroll-event because of slidingMap
      $(document).focus();
      $(window).scroll();
    }
  });
  $(".thumbListWrapper", "#assignPhotosWrapper").droppable({
    accept: ".willBeAccepted",
    activeClass: "droppable-active",
    hoverClass: "droppable-hover",
    drop: function drop(ev, ui) {
      //console.log("Foto wurde wieder in den Pool verschoben");

      // don't drop if in assignPhotosWrapper itself
      if (!$lastMediaHolder) {
        return;
      }

      // remove draggable from mediaHolder and add it to mediaPool
      moveDraggableToPool(ui.draggable, $(this));

      // extract image id from draggable
      var queryString = ui.draggable.find("a").attr("href").replace(/.*?\?/, "");
      var sImageIdFromUrl = queryString.replace(/.*pid\=/, "");
      sImageIdFromUrl = sImageIdFromUrl.replace(/\&.*$/, "");

      // upload photo back to the pool
      var sUrl = "/foto/anulling/" + sImageIdFromUrl + "/";
      $.get(sUrl, function () {}).done(function () {
        //console.log("Data successfully loaded:", data, "textStatus:",textStatus);
      }).fail(function (jqXHR, textStatus, errorThrown) {
        gol.gen.alert(errorThrown);
      }).always(function () {});

      // Set focus back to body and fire scroll-event because of slidingMap
      $(document).focus();
      $(window).scroll();
    }
  });
  moveDraggableToPool = function moveDraggableToPool($draggable, $droppable) {
    // get mediaHolder
    var $newMediaContainer = getMediaHolderFromDraggable($draggable);
    // append draggable to photoPool-list
    $draggable.appendTo($("ul", $droppable)).wrap("<li></li>");
    // hide mediaHolder if empty
    if (getHolderIsEmpty($newMediaContainer)) {
      $newMediaContainer.hide();
    }
  };
  removeDraggableFromMediaHolder = function removeDraggableFromMediaHolder($draggable) {
    // get mediaHolder
    var $newMediaContainer = getMediaHolderFromDraggable($draggable);
    // Remove draggable after dropping on trashcan
    // console.log("newMediaContainer =", $newMediaContainer, " | draggable =", $draggable);

    $draggable.hide(); // hide because of display bug with remove(). Pic was still visible after removing
    // hide mediaHolder if empty
    checkVisabilityMediaHolder($newMediaContainer);
  };
  getMediaHolderFromDraggable = function getMediaHolderFromDraggable(oDraggable) {
    var $holder = $(oDraggable).parents(".newAssignedPhotos");
    return $holder.length > 0 ? $holder : 0;
  };
  getHolderIsEmpty = function getHolderIsEmpty($holder) {
    // return true if holder exists and no images are in it
    return $holder && $holder.length > 0 ? $holder.find(".imgLoc").length <= 0 ? true : false : 0;
  };
  checkVisabilityMediaHolder = function checkVisabilityMediaHolder($holder) {
    if (getHolderIsEmpty($holder)) {
      $holder.hide();
    }
  };

  /* ***************************************/
  /* *          public functions         ***/
  /* ***************************************/

  //
  // end of closure
  //
})(jQuery);