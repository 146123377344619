"use strict";

// GoLocal-utilities
// gl.utils.js

(function glUtils() {
  "use strict";

  var glJQueryMethods = {
    check: function check(mode) {
      mode = mode || "on"; // if mode is undefined, use 'on' as default
      return this.each(function () {
        switch (mode) {
          case "on":
            this.checked = true;
            break;
          case "off":
            this.checked = false;
            break;
          case "toggle":
            this.checked = !this.checked;
            break;
        }
      });
    }
  };

  // Set methods to jQuery-methods
  $.each(glJQueryMethods, function (i) {
    jQuery.fn[i] = this;
  });

  /* ever needed?
  $.gl_ensureEnterOnForms = function(form){
  	var $form = $(form);
  	$('input', $form).keydown(function(e){
  		if (e.keyCode == 13) {
  			$(this).parents('form').submit();
  			return false;
  		}
  	});
  };
  
  
  $.serialize = function(obj, re) {
  	var result = [];
  	$.each(obj, function(i, val) {
  		if ((re && re.test(i)) || !re)
  			result.push(i + ': ' + (typeof val == 'object' ? val.join ? '\'' + val.join(', ') + '\'' : serialize(val) : '\'' + val + '\''));
  	});
  	return '{' + result.join(', ') + '}';
  };*/

  $.escapeHTML = function (text) {
    return text.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };
  $.maskTextSelection = function ($obj) {
    var oSelection = $obj.getSelection();
    var sText = oSelection.text;
    //console.log("sText = ", sText);

    var reAllWordChars = /[^\s.,!?]/g;
    var newString = sText.replace(reAllWordChars, "X");
    //console.log("neuer String =", newString);

    $obj.replaceSelectedText(newString);
  };

  // .toggle wurde in jQuery 2.0 als deprecated markiert, dementsprechend ein Ersatz
  $.fn.toggler = function (fn) {
    var args = arguments,
      guid = fn.guid || $.guid++,
      i = 0,
      toggler = function toggler(event) {
        var lastToggle = ($._data(this, "lastToggle" + fn.guid) || 0) % i;
        $._data(this, "lastToggle" + fn.guid, lastToggle + 1);
        event.preventDefault();
        return args[lastToggle].apply(this, arguments) || false;
      };
    toggler.guid = guid;
    while (i < args.length) {
      args[i++].guid = guid;
    }
    return this.on("click", toggler);
  };

  // copy the text of the id given
  $.copyToClipboard = function (elem) {
    // create hidden text element, if it doesn't already exist
    var targetId = "_hiddenCopyText_";
    var isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
    var origSelectionStart, origSelectionEnd, target;
    if (isInput) {
      // can just use the original source element for the selection and copy
      target = elem;
      origSelectionStart = elem.selectionStart;
      origSelectionEnd = elem.selectionEnd;
    } else {
      // must use a temporary form element for the selection and copy
      target = document.getElementById(targetId);
      if (!target) {
        target = document.createElement("textarea");
        target.style.position = "absolute";
        target.style.left = "-9999px";
        target.style.top = "0";
        target.id = targetId;
        document.body.appendChild(target);
      }
      target.textContent = elem.textContent;
    }
    // select the content
    var currentFocus = document.activeElement;
    target.focus();
    target.setSelectionRange(0, target.value.length);

    // copy the selection
    var succeed;
    try {
      succeed = document.execCommand("copy");
    } catch (e) {
      succeed = false;
    }
    // restore original focus
    if (currentFocus && typeof currentFocus.focus === "function") {
      currentFocus.focus();
    }
    if (isInput) {
      // restore prior selection
      elem.setSelectionRange(origSelectionStart, origSelectionEnd);
    } else {
      // clear temporary content
      target.textContent = "";
    }
    return succeed;
  };

  // get parameter from url with
  // $.urlParam('param1')
  $.urlParam = function (name) {
    var results = new RegExp("[\?&]" + name + "=([^&#]*)").exec(window.location.href);
    if (results === null) {
      return null;
    } else {
      return decodeURI(results[1]) || 0;
    }
  };

  // generate and return UUID V4
  $.getUuid = function () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (c) {
      var crypto = window.crypto || window.msCrypto;
      return (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16);
    });
  };

  // check passwort strength
  $.setPasswordStrengthIndicator = function ($pw, $strengthInfo) {
    $strengthInfo.append("<div class='pw-strength__txt'></div><div class='pw-strength__bar-holder'><div class='pw-strength__bar'></div></div>");
    var $strengthInfoTxt = $(".pw-strength__txt", $strengthInfo);
    $pw.on("keypress keyup keydown", function () {
      var pass = $(this).val(),
        sLbl = "";
      var sPassStrength = checkPassStrength(pass);
      switch (sPassStrength) {
        case "Schwach":
          $strengthInfo.attr("class", "");
          $strengthInfo.addClass("weak");
          sLbl = "Passwortstärke: ";
          if ($strengthInfo.is(":hidden")) {
            $strengthInfo.slideDown(300);
          }
          break;
        case "Gut":
          $strengthInfo.attr("class", "");
          $strengthInfo.addClass("good");
          sLbl = "Passwortstärke: ";
          if ($strengthInfo.is(":hidden")) {
            $strengthInfo.slideDown(300);
          }
          break;
        case "Stark":
          $strengthInfo.attr("class", "");
          $strengthInfo.addClass("strong");
          sLbl = "Passwortstärke: ";
          if ($strengthInfo.is(":hidden")) {
            $strengthInfo.slideDown(300);
          }
          break;
        default:
          $strengthInfo.attr("class", "");
          if ($strengthInfo.is(":visible")) {
            $strengthInfo.slideUp(300);
          }
      }
      $strengthInfoTxt.html(sLbl + sPassStrength.toUpperCase());
      //$("#strength_score").text(scorePassword(pass));
    });

    // private functions 
    function scorePassword(pass) {
      var score = 0;
      if (!pass) {
        return score;
      }

      // award every unique letter until 5 repetitions
      var letters = {};
      for (var i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
      }

      // bonus points for mixing it up
      var variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass)
      };
      var variationCount = 0;
      for (var check in variations) {
        if (variations.hasOwnProperty(check)) {
          // takes care that variations does not inherited unwanted objects from the prototype chain
          variationCount += variations[check] === true ? 1 : 0;
        }
      }
      score += (variationCount - 1) * 10;
      return parseInt(score);
    }
    function checkPassStrength(pass) {
      var score = scorePassword(pass);
      if (score > 60) {
        return "Stark";
      }
      if (score > 40) {
        return "Gut";
      }
      if (score >= 0) {
        return "Schwach";
      }
      return "";
    }
  };
})(jQuery);