"use strict";

/**
* JS für LocationLists
* @author GoLocal GmbH & Co. KG
* @version 1.0
*/

(function glExtendLists() {
  "use strict";

  gol.lists = {
    init: function init(options) {
      //console.log("Lists initialized");

      var pageNo = gol.lists.getParameterFromUrl("p");
      if (pageNo === undefined || pageNo === null || pageNo === "") {
        pageNo = 1;
      }
      gol.lists.trackHitListPageView(pageNo);
      $(".listTypeWrap, .sortListWrap, .distListWrap", ".refineSearchWrap").glResultFilter();
      $(".refineSearchWrap li:not(.active, .disabled) a, .newRegionSearchWrapper a").on("click", function () {
        $("div.refineSearchWrap").block({
          message: null,
          // styles for the overlay
          overlayCSS: {
            backgroundColor: "#f6f7e8",
            opacity: 0.6
          }
        });
      });
      this.pagingInit(pageNo);
      this.spellcheck.init();
      if (options.isSearchList) {
        $("li.sponsoredWrapper div.content").glSponsoredListings({
          flipInterval: 5500
        });
      }
    },
    spellcheck: {
      init: function init() {
        $(document).on("click", "#didYouMean", function () {
          var $this = $(this);
          var didYouMean = $this.data("didyoumean");
          $("#txtSearchTerm").val(didYouMean);
          $("#glbtn").click();
          return false;
        });
      }
    },
    addHandlerMoreLess: function addHandlerMoreLess() {
      $("#cityListContent .showLessOrMoreNeighbours").on("click", function () {
        //console.log("anchor clicked");
        $(this).off("click");
        var sUrl = $(this).attr("href");
        $.get(sUrl, function (data) {
          $("#citiesNearBy").replaceWith(data);

          // refresh dialog
          $("#dialog").html($("#cityListWrapper").html());
          gol.lists.addHandlerMoreLess();
          //console.log("Speichern erfolgreich");
        });
        return false;
      });
    },
    pagingInit: function pagingInit(pageNo) {
      try {
        if ("pushState" in history && history.pushState !== null) {
          if (pageNo === 1) {
            history.pushState({
              pageNo: pageNo
            }, null, location.href);
          }
          window.onpopstate = function (event) {
            if (event.state !== null) {
              window.location.reload();
            }
          };
        }
      } catch (e1) {
        //console.log("pushState not available, error="+e1);
      }
    },
    trackHitListPageView: function trackHitListPageView(pageNo) {
      //console.log("trackHitListPageView");
      ga("send", "pageview");
      try {
        var wwa$ = window.wwa$ || {};
        wwa$.setPageAttr({
          r_pgno: pageNo
        });
        wwa$.logPageView();
      } catch (e) {}
    },
    trackHitListEntries: function trackHitListEntries(items, type) {
      //console.log("trackHitListEntries");
      try {
        var wwa$ = window.wwa$ || {};
        wwa$.logItemView(items, type);
      } catch (e) {}
    },
    getParameterFromUrl: function getParameterFromUrl(name) {
      var encName = new RegExp("[?&]" + encodeURIComponent(name) + "=([^&]*)").exec(location.search);
      if (encName !== null) {
        return decodeURIComponent(encName[1]);
      }
    }
  };
})(jQuery);