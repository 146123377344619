"use strict";

/**
 * Notepad
 *
 * @version 1.0.0 (28.01.2008)
 */
(function glNotepad() {
  //
  // plugin definition
  //

  "use strict";

  var opts;

  // Begin fn.glNotepad
  jQuery.fn.glNotepad = function (options) {
    //console.log("$.glNotepad() called");

    // build main options before element iteration
    opts = $.extend({}, $.fn.glNotepad.defaults, options);
    return this.each(function () {
      //var $this = $(this);

      // Support the metadata-plugin
      //var o = $.meta ? $.extend({}, opts, $this.data()) : opts;

      $.fn.glNotepad.maps.init();
      $.fn.glNotepad.photos.init();
    });
  }; // End fn.glNotepad

  //
  // plugin defaults
  //
  $.fn.glNotepad.defaults = {};
  var imgMap;
  //
  // private functions
  //

  $.fn.glNotepad.photos = {
    init: function init() {
      this.nPhotoWidth = 90;
      this.setHandlers();
    },
    setHandlers: function setHandlers() {
      var self = this,
        $photosWrapper;
      $("a.showPhotos").toggler(function () {
        var $this = $(this);
        var sHref = $this.attr("href");
        $photosWrapper = self.getPhotoWrapperFromElement($this);
        // set hiding-text
        self.getParentLiFromElement($this).find("a.showPhotos").html("[ausblenden]");
        $photosWrapper.load(sHref, function (data) {
          //console.log("Fotos fertig geladen:", data);

          // set width of pics-list
          var $lis = $(data).find("li");
          var nLength = $lis.size();
          var nWidth = nLength * self.nPhotoWidth;

          // set width of wrapper and show photos
          $photosWrapper.find("ul").css("width", nWidth);
          $photosWrapper.slideDown(800);
        });
      }, function () {
        $photosWrapper = self.getPhotoWrapperFromElement(this);
        // set showing-text and hide photos
        self.getParentLiFromElement(this).find("a.showPhotos").html("[anzeigen]");
        $photosWrapper.slideUp(800);
      });
    },
    getPhotoWrapperFromElement: function getPhotoWrapperFromElement(element) {
      return $(element).parents("li").find("div.uploadedPhotosWrapper");
    },
    getParentLiFromElement: function getParentLiFromElement(element) {
      return $(element).parents("li");
    }
  };

  /* **************************************/
  /* *             Maps                 ***/

  $.fn.glNotepad.maps = {
    init: function init() {
      //console.log("init called");
      this.setHandlers();
    },
    setHandlers: function setHandlers() {
      var self = this;
      $("li.showInMap a", "div.actionsWrapper").on("click", function () {
        self.$currLink = $(this);
        self.toggleMap($(this));
        this.blur();
        return false;
      });
    },
    toggleMap: function toggleMap($anchor) {
      //console.log("toogleMap() called");
      var $wrapper = $anchor.parents("div.actionsWrapper");

      //console.log("wrapper = ", $wrapper);
      if ($wrapper.prev("div.locationMap").size() <= 0) {
        var sHref = $anchor.attr("href");
        //console.log("href=", sHref);
        // Preload Image
        imgMap = new Image();
        imgMap.name = "googleMap.jpg";
        imgMap.onload = $.fn.glNotepad.maps.applyAndShowMapImage;
        imgMap.src = sHref;
        $wrapper.before("<div class='locationMap' style='display:none;'><img src='' width='512' height='260' alt='Kartenposition der Location' /></div>");
        this.$currMap = $wrapper.prev("div.locationMap");
        this.showLoader();
      } else {
        this.$currMap = $wrapper.prev("div.locationMap");
        var $locationMap = $wrapper.prev("div.locationMap");
        //console.log("isVisible?", $locationMap.is(":visible") );

        if ($locationMap.is(":visible")) {
          this.hideMap();
        } else {
          this.showMap();
        }
      }
    },
    applyAndShowMapImage: function applyAndShowMapImage() {
      // Apply preloaded image locationMap
      $.fn.glNotepad.maps.$currMap.find("img").attr("src", imgMap.src);
      $.fn.glNotepad.maps.showMap();
    },
    showMap: function showMap() {
      //console.log("showMap(", this.$currMap, ") called");
      //console.log("showMap()");
      //this.$currMap.slideDown("slow");
      this.$currMap.animate({
        height: "show"
      }, {
        duration: 600,
        easing: "easeInOutSine"
      });
      this.hideLoader();
      this.$currLink.find("span.icon").text("Karte ausblenden");
      this.$currLink.parents("li.showInMap").removeClass("showInMap").addClass("hideMap");
    },
    hideMap: function hideMap() {
      //console.log("hideMap(", this.$currMap, ") called");
      //this.$currMap.slideUp("slow");
      this.$currMap.animate({
        height: "hide"
      }, {
        duration: 500,
        easing: "easeInOutSine"
      });
      this.$currLink.find("span.icon").text("in Karte anzeigen");
      this.$currLink.parents("li.hideMap").removeClass("hideMap").addClass("showInMap");
    },
    showLoader: function showLoader() {
      //console.log("showLoader() called");
      this.sOrigBtnText = this.$currLink.html();
      this.$currLink.html("<span class='loadingText'>...wird geladen</span>");
      var $buttonWrapper = this.$currLink.parents("li.showInMap");
      $buttonWrapper.prepend("<div class='loader'><img src='/images/common/indicator.gif' width='16' height='16' alt='Kartenposition der Location' /></div>");
    },
    hideLoader: function hideLoader() {
      //console.log("hideLoader() called");
      this.$currLink.html(this.sOrigBtnText);
      this.$currLink.parents("li.showInMap").find("div.loader").remove();
    }
  };
  $("#mglContent.notepad").glNotepad({});

  //
  // end of closure
  //
})(jQuery);