"use strict";

/*
 *
 * glSelectMenu for responsive menus
 * Version 1.0.0  (27.04.2018)
 *
 * https://www.golocal.de
*/
//
// create closure
//

(function glSelectMenu() {
  /* ***************************************/
  /* *         plugin definition         ***/
  /* ***************************************/
  "use strict";

  jQuery.fn.glSelectMenu = function (options) {
    //console.log("glSelectMenu(", options, ") called");
    var opts, $ddContent;
    // build main options before element iteration
    opts = $.extend({}, $.fn.glSelectMenu.defaults, options);
    return this.each(function () {
      //console.log("glSelectMenu(",options,") called");
      var $this = $(this);
      var $elements = $(options.linkClasses, $this),
        $anchor,
        sLinktext,
        $anchorParent;

      // set special class name for options if given
      var sOptionsClassName = options.optionsClassName ? " " + options.optionsClassName : "";

      // store initial options of selectfield
      $ddContent = $(options.navDropdown).html();

      // go through each link of navigation, get some properties and create the select options
      $.each($elements, function () {
        $anchor = $(this);
        $anchorParent = $anchor.parent();
        sLinktext = $anchor.text();

        // generate option
        $ddContent += "<option value='" + $anchor.attr("href") + "'";
        if ($anchorParent.hasClass("active")) {
          //console.log("AKTIV: ", $anchorParent);
          $ddContent += " selected";
        }
        $ddContent += " class='nav-dropdown__option" + sOptionsClassName + "'>";
        $ddContent += sLinktext + "</option>";
      });

      //console.log("content =", $ddContent);

      $(options.navDropdown).html($ddContent);
    });
  }; // End fn.glSelectMenu

  /* ***************************************/
  /* *         plugin defaults           ***/
  /* ***************************************/

  $.fn.glSelectMenu.defaults = {};

  /* ***************************************/
  /* *          public functions         ***/
  /* ***************************************/

  //
  // end of closure
  //
})(jQuery);
$(function () {
  "use strict";

  // dependend on page there are missing ids (either navlist on mygolocal or the other two on forum-page). Maybe it's better to put this initialization in bottom.jsp. Advantage her: All is in one js-file.
  $("#navlist").glSelectMenu({
    linkClasses: ".forum-nav-a",
    navDropdown: ".topicnav-dropdown"
  }); // forum
  $("#mglNav-mygolocal").glSelectMenu({
    linkClasses: ".mgl-nav-a, .mgl-nav-a-blue",
    navDropdown: ".nav-dropdown"
  }); // mygolocal
  $("#mglNav-myprofile").glSelectMenu({
    linkClasses: ".mgl-nav-a, .mgl-nav-a-blue",
    navDropdown: ".nav-dropdown",
    optionsClassName: "nav-dropdown__option--profile"
  }); // myprofile in mygolocal

  $(".mglSubNav").glSelectMenu({
    linkClasses: ".mglSubNav-a",
    navDropdown: ".tabnav-dropdown"
  }); // mygolocal (tab subnav)

  $(".rankingSubNav").glSelectMenu({
    linkClasses: ".rankingSubNav-a",
    navDropdown: ".nav-dropdown"
  }); // Highscores Subnav
});