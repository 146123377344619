"use strict";

(function glExtendSidebar() {
  "use strict";

  gol.sideBar = {
    init: function init() {
      //console.log("gol.sideBar.init() called");

      this.$sideBar = $("#sideBar");
      this.$sideBarNav = $("ul#sideBarNav");
      this.arrLayerIds = [];
      this.booMapReady = false;
      this.booNoNav = false;
      if (this.$sideBarNav.length) {
        this.initNav();
        this.setNavLinks();
        if (this.$currLayer === "assignPhotosWrapper") {
          this.showSideBarElement(this.getCurrentNavId());
        }
      } else {
        this.booNoNav = true;
      }
      this.initDontMoveLabel();
      // init resizing
      this.size.init();

      // set initialization flag
      this.booInitialized = true;
    },
    initNav: function initNav() {
      //console.log("sideBar.initNav() called");

      this.$sideBarNav = $("ul#sideBarNav");
      var $li = this.$sideBarNav.find("li.current");
      var sTargetId = $li.find("a").attr("rel");
      this.$currLayer = sTargetId;
      this.$lastLayer = null;
      //console.log("currentLayer = ", this.$currLayer);
      // don't show loader if map is not available
      if (this.$currLayer === "glMap" && gol.map.booInitialized) {
        this.showLoader();
      }
      this.setCurrNavItemFromLi($li);
    },
    setNavLinks: function setNavLinks() {
      //console.log("setNavLinks() called");
      var self = this;
      this.$sideBarNavAnchors = $("a", this.$sideBarNav).each(function () {
        self.arrLayerIds.push($(this).attr("rel"));
      }).on("click", function () {
        var sTargetId = $(this).attr("rel");
        if (sTargetId !== "glMap") {
          GlMap.setResizedMap();
        }

        // do functions after clicking anchor
        self.doNavLinkFunctions($(this));
        return false;
      });
    },
    doNavLinkFunctions: function doNavLinkFunctions($anchor) {
      //console.log("doNavLinkFunctions(", $anchor, ") clicked");
      // get target id
      var sTargetId = $anchor.attr("rel");
      if (sTargetId === this.$currLayer || sTargetId === "") {
        return false;
      }

      // if last layer was map and new anchor was clicked
      // reduce map first and let map-object call click link afterwards
      /*if(gol.map.size.booWideMap){
      	gol.map.size.reduce($anchor);
      	return false;
      }*/

      //sTargetId == "glMap" ? this.mapSizeSwitch.enable() : this.mapSizeSwitch.disable();

      this.$lastLayer = this.$currLayer;
      this.$currLayer = sTargetId;

      //console.log("currLayer =", this.$currLayer);

      this.showSideBarElement(sTargetId);

      // switch cookie on every click on any element for this bit-group (4th bit)
      if (sTargetId === "glMap" || sTargetId === "assignPhotosWrapper") {
        /* jshint bitwise:false */
        gol.settings.setCookie(gol.settings.getCookie() ^ 4);
      }

      // highlight active nav-element
      this.setCurrNavItemFromLi($anchor.parents("li"));
    },
    getCurrentNavId: function getCurrentNavId() {
      var $currentLi = this.$sideBarNav.find("li.current a");
      //console.log("currentLi =", $currentLi);
      //console.log("currentLiId =", $currentLi.attr("rel"));
      return $currentLi.attr("rel");
    },
    setCurrNavItemFromLi: function setCurrNavItemFromLi($li) {
      //console.log("setCurrNavItemFromLi(", $li, ") called");
      // add class active to current clicked anchor
      // and remove it from all other anchors
      $li.parents("ul").find("li").removeClass("current");
      $li.addClass("current");
    },
    isMapSelected: function isMapSelected() {
      //return true if map is currently selected
      return this.$currLayer === "glMap" ? true : false;
    },
    selectMap: function selectMap() {
      // directly activate the map
      this.switchTo("glMap");
    },
    switchTo: function switchTo(sTargetId) {
      var $this;
      // no sideBarNavAnchors available in events page
      if (this.$sideBarNavAnchors) {
        // find anchor of sidebarNavigation with given targetID in rel-attribute and click it
        this.$sideBarNavAnchors.each(function () {
          $this = $(this);
          if ($this.attr("rel") === sTargetId) {
            $this.click();
          }
        });
      }
    },
    showSideBarElement: function showSideBarElement(sTargetId) {
      // go through stored layers
      $.each(this.arrLayerIds, function (index, id) {
        // show current and hide all other layers
        if (id !== sTargetId) {
          $("#" + id).css({
            display: "none",
            visibility: "hidden"
          });
        } else {
          $("#" + id).css({
            display: "block",
            visibility: "visible"
          });
        }
      });
      if (sTargetId === "glMap") {
        if (!GlMap.isResizedMap()) {
          GlMap.checkResizeMap();
        }
        this.setDontMoveLabel("Karte, bleib wo Du bist!");
        //???
        if (!this.getMapReady()) {
          this.showLoader();
          setTimeout(function () {
            GlMap.initialize();
            if (!gol.map || !gol.map.booInitialized) {
              gol.map.init();
            }
          }, 100);
          //this.booMapReady = true;
        }
        $("div.mapActions", this.$sideBar).show();
      } else {
        this.setDontMoveLabel("Fotos, bleibt wo ihr seid!");
        $("div.mapActions", this.$sideBar).hide();
      }
    },
    initDontMoveLabel: function initDontMoveLabel() {
      if (gol.map && gol.map.booInitialized) {
        $(".dontMoveWrapper", this.$sideBar).show();
      }
      // set specified map-text if map is selected or area has no navigation (for events-section)
      this.setDontMoveLabel(this.isMapSelected() || this.booNoNav ? "Karte, bleib wo Du bist!" : "Fotos, bleibt wo ihr seid!");
    },
    setDontMoveLabel: function setDontMoveLabel(sText) {
      $(".dontMoveWrapper label", this.$sideBar).html(sText);
    },
    // called in gl.map.js
    mapReady: function mapReady() {
      //console.log("mapReady() called");
      this.booMapReady = true;
      this.hideLoader();
    },
    getMapReady: function getMapReady() {
      return this.booMapReady;
    },
    showLoader: function showLoader() {
      //console.log("showLoader() called");
      if (!this.$loader) {
        this.$loader = this.$sideBarNav.append("<div class='loader' />").find("div.loader");
      }
      this.$loader.show();
    },
    hideLoader: function hideLoader() {
      if (this.$loader) {
        this.$loader.hide();
      }
    },
    mapSizeSwitch: {
      enable: function enable() {
        $(".mapSizeSwitch").removeClass("disabled");
      },
      disable: function disable() {
        $(".mapSizeSwitch").addClass("disabled");
      },
      isEnabled: function isEnabled() {
        return gol.sideBar.size.$switch.hasClass("disabled") ? false : true;
      }
    },
    size: {
      init: function init() {
        this.setHandlers();

        // enable resizeSwitch only in location or event list
        if (gol.local.data && gol.local.data.booResizableMap) {
          gol.sideBar.mapSizeSwitch.enable();
        }

        // check cookie and set variable of size-status
        // dependend if sideBar is reduced or not
        if (this.isEnlarged()) {
          this.booWideMap = true;
          this.$switch.html("[verkleinern]");
        } else {
          this.booWideMap = false;
          this.$switch.html("[vergr&ouml;&szlig;ern]");
        }
      },
      setHandlers: function setHandlers() {
        var self = this;
        this.$switch = $(".mapSizeSwitch", "#sideBar");
        this.$switch.on("click", function () {
          /* jshint bitwise:false */

          // return if map is just animating or is disabled
          if (self.booAniRunning || !gol.sideBar.mapSizeSwitch.isEnabled()) {
            return false;
          }
          // reduce or enlarge map depending on mapStatus
          if (self.booWideMap) {
            self.reduce();
          } else {
            self.enlarge();
          }

          // set cookie for storing width of sideBar
          gol.settings.setCookie(gol.settings.getCookie() ^ 1);
          //console.log("isEnlarged =", self.isEnlarged());

          return false;
        });
      },
      enlarge: function enlarge() {
        var self = this;
        this.booAniRunning = true;
        gol.trackEvent("mapSizeSwitch", "enlarge");

        // reduce resultList
        // and take care of shown locationEvents (hide second Event)
        /*$("#resultWrapper .locListWrapper")
        	.animate(
        		{"width": "445px"},
        		1000,
        		"easeOutSine",
        		function(){
        			// map is wide
        			self.booWideMap = true;
        			self.booAniRunning = false;
        			GlMap.resizeMap();
        		}
        	)
        	.addClass("narrow");*/

        $("#resultWrapper").removeClass("large-7 medium-8").addClass("large-5 medium-6");

        // and enlarge map
        //$("#sideBar").animate({"width": "520px"}, 1000, "easeOutSine");

        $("#sideBar").removeClass("large-5 medium-4").addClass("large-7 medium-6");

        /*if ($("#glMap:visible").size() > 0) {
        	$("#glMap").animate({"width": "520px"}, 1000, "easeOutSine");
        }
        else {*/
        $("#glMap").css("width", "100%");
        //}

        self.booWideMap = true;
        self.booAniRunning = false;

        // change link text
        this.$switch.text("[verkleinern]");
      },
      reduce: function reduce() {
        var self = this;
        this.booAniRunning = true;
        gol.trackEvent("mapSizeSwitch", "reduce");

        // enlarge resultList
        // and take care of shown locationEvents (show second Event)
        /*$("#resultWrapper .locListWrapper")
        	.animate(
        		{"width": "620px"},
        		1000,
        		"easeOutSine",
        		function(){
        			// map is narrow
        			self.booWideMap = false;
        					$(this)
        				.removeClass("narrow");
        			$("#resultWrapper").removeClass("maxSideBar");
        			// if anchor is given from sideBar
        			// click it after animation has finished
        			//$anchor ? setTimeout(function(){$anchor.click();}, 100) : 0;
        					self.booAniRunning = false;
        		}
        	);*/

        $("#resultWrapper").addClass("large-7 medium-8").removeClass("large-5 medium-6");

        //$("#sideBar").animate({"width": "345px"}, 1000, "easeOutSine");

        $("#sideBar").addClass("large-5 medium-4").removeClass("large-7 medium-6");

        /*if ($("#glMap:visible").size() > 0) {
        	$("#glMap").animate({"width": "345px"}, 1000, "easeOutSine");
        }
        else {*/
        $("#glMap").css("width", "100%");
        //}

        self.booWideMap = false;
        self.booAniRunning = false;

        // change link text
        this.$switch.html("[vergr&ouml;&szlig;ern]");
      },
      isEnlarged: function isEnlarged() {
        /* jshint bitwise:false */

        // check cookie
        return gol.settings.getCookie() & 1;
      }
    }
  };
})(jQuery);
$(function () {
  "use strict";

  gol.sideBar.init();
});