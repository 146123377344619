"use strict";

// gl.extend-newsstream.js
//
// depends on gl.common.js
//
(function glExtendNewsstream() {
  "use strict";

  var opt, myValue, whereAmI, newsStreamOnStartPage;
  gol.newsstream = {
    init: function init(options) {
      if (options.newsStreamOnStartPage === true) {
        newsStreamOnStartPage = true;
      } else {
        newsStreamOnStartPage = false;
      }

      // beginning of the new pushState solution part 1/?

      if (history.state !== null && history.state.data !== null) {
        //console.log("1st history.state!==null && history.state.data!==null");

        $("#toHistoryState").html(history.state.data);
      }
      window.onpopstate = function () {
        console.log("window.onpopstate");
        function fromSuccess(txt) {
          options.chunked = false;
          options.nofPagesSkipped = -1;

          //gol.newsstream.nsFilter.toggleVisibilityForOwnProfile(opt.ownUserPage, options.includeHeroes);

          // just refresh the whole list if clicked on tab
          // or doing an ajax-browser-back-refresh (chunked == false)
          if (options.nofPagesSkipped === -1 || options.chunked === false) {
            if ($("li.refreshNs").length > 0) {
              //console.log("html");

              //$("#mgl-newslist").html(txt); // it's already there
              if (txt.indexOf("nsFilterWrap") !== -1) {
                //console.log("newNsFilterWrap 2");

                //$("#nsFilterMarker #nsFilterWrap").remove(); // because why would I need it here?
                //$("#nsFilterMarker").append($("#nsFilterWrap"));

                //init nsFollowers
                $("#txtNewNsLocality").suggest("/suggestLocality/", {
                  onSelect: function onSelect() {
                    this.focus();
                    this.value = this.value;
                  }
                });

                // init tagging
                gol.newsstream.tagging.init({
                  nMaxNsLocalityKeys: opt.maxNsLocalityKeys
                });
                var $elem = $("#nsFollowers").find("li.popular");
                $elem.off("click");
                $elem.on("click", function () {
                  var $this = $(this);
                  $this.toggleClass("selected");
                  gol.newsstream.extendNewsStream({
                    nofPagesSkipped: -1,
                    includeHeroes: true,
                    newNsLocality: null,
                    command: "toggleIncludeHeroes"
                  });
                  this.blur();
                  return false;
                });
              }
            }
            if (txt.indexOf("unknownNsLocality") !== -1) {
              //last li in nsLocalities is not a valid locality
              var $lastLi = $("li.added").last();
              $("#nsErrorMessage").append("<strong class='infoMsg_message'>&bdquo;" + $lastLi.text() + "&rdquo; ist kein uns bekannter Ort</strong>").show();
              $lastLi.remove();
              gol.newsstream.tagging.checkLocInputVisibility();
              var $input = $("#txtNewNsLocality");
              $input.val($lastLi.text()).focus();
            }
          } else {
            // doing an li-replacement at the end of the existing list
            // with the new expanded (chunked) list
            var $replaceWrap = $("#extendNewsStreamReplaceMarker");
            $replaceWrap.replaceWith(txt);
          }

          // because if it's necessary then its already being shown in an entry from the history
          /*	if (txt.indexOf("nsNothingSelected")!==-1)
          	{
          		$("#nsErrorNothingSelected")
          			.append("<strong class='infoMsg_message'>Damit du hier Neuigkeiten siehst, wähle oben bitte etwas aus!</strong>")
          			.show();
          	}	*/

          // show news column in case it was hidden for browser-back-purposes
          // (hidden in the header)
          //	$(".mgl-news-col").show();

          // TimeAgo erneut initialisieren
          //gol.timeago.refresh();

          // set tooltip
          // toDo: script mit dem in common.js kombinieren
          setTimeout(function () {
            console.log("from success set tooltip");
            /* var $elements = $("a.avatarImage, a.toolTipLink");
            		// remove existing handlers first
            $elements.off("click");
            		gol.tooltips.setupGolocalUser($elements);
            		$(".addInfoTooltipWide").cluetip(gol.tooltips.getAddInfoOptionsWide()); */
            gol.foundation.init();
            $(document).foundation();
          }, 500);
          if (gol.local.data.isValidUser) {
            gol.newsstream.initRemovableMedia();
          }
        }
        if (history.state !== null && history.state.data !== null) {
          //console.log("history.state !== null && history.state.data !== null");

          $("#toHistoryState").html(history.state.data);
          gol.newsstream.setHandlers();
          gol.newsstream.nsFilter.setHandlers();
          gol.newsstream.tagging.setReturnHandler($("#txtNewNsLocality"));
          gol.newsstream.tagging.setButtonHandler($("#btnSubmitTag"), $("#nsLocalities"));
          //console.log("from success it shouldn't show up now");
          var txt;
          txt = $(".contentWrapp").html().toString();
          fromSuccess(txt);
        }
      };
      // end of the new pushState solution part 1/?

      //console.log("newsstream.init called");

      opt = options;
      gol.newsstream.setHandlers();
      gol.newsstream.nsFilter.setHandlers();

      // Nur bei eigenen Userprofil
      if (opt.ownUserPage) {
        $("#txtNewNsLocality").suggest("/suggestLocality/", {
          onSelect: function onSelect() {
            this.focus();
            this.value = this.value;
          }
        });

        // init tagging
        gol.newsstream.tagging.init({
          nMaxNsLocalityKeys: options.maxNsLocalityKeys
        });
        var $elem = $("#nsFollowers").find("li.popular");
        $elem.off("click");
        $elem.on("click", function () {
          var $this = $(this);
          $this.toggleClass("selected");
          gol.newsstream.extendNewsStream({
            nofPagesSkipped: -1,
            includeHeroes: true,
            newNsLocality: undefined,
            command: "toggleIncludeHeroes"
          });
          this.blur();
          return false;
        });
      }
      if (gol.local.data.isValidUser) {
        gol.newsstream.initRemovableMedia();
      }

      //gol.newsstream.initNewsstream(booLocHasHash);

      // part of the new pushState solution
      // deals with direct entries to e.g. https://www.golocal.de/meingolocal/#/nur-meine/
      var currentURL, pos, sub;
      currentURL = window.location.href;
      function getValueFromURL(currentURL) {
        if (currentURL.lastIndexOf("#") !== -1) {
          pos = currentURL.lastIndexOf("#");
          sub = currentURL.substring(pos + 1);
          if (sub.charAt(0) === "/") {
            sub = sub.substring(1);
          }
          if (sub.charAt(sub.length - 1) === "/") {
            sub = sub.substring(0, sub.length - 1);
          }
        }
        if (currentURL.lastIndexOf("#") === -1) {
          pos = "";
          sub = "";
        }
        return pos, sub;
      }
      getValueFromURL(currentURL);
      if (sub) {
        // initialize tooltips when page with # in url was refreshed
        gol.foundation.init();
        $(document).foundation();
      }
      var myAttributes = $("#toHistoryState .mglSubNav-li a"),
        ownURL;
      (function () {
        function makeHistory(myValue) {
          var newStateObj, newData, currentURL;
          var ownURL;
          currentURL = window.location.href;

          //console.log("### makeHistory 0");
          if (typeof myValue !== "undefined") {
            //console.log("### makeHistory 1");

            if (myValue.nodeType === 1) {
              //console.log("### makeHistory 2");
              ownURL = myValue.getAttribute("data-ownurl");
              var properAdress = "/meingolocal/#/" + ownURL + "/";
              if (ownURL.substring(0, 5) === "user/") {
                properAdress = "/" + ownURL + "/";
              }
              //console.log("### makeHistory 3");
              /*
                	var properAdress = "/meingolocal/#/"+ownURL+"/";
              	if (ownURL !== null){	if (ownURL.substring(0,5) === "user/"){ properAdress = "/"+ownURL+"/";} }
               */

              if (whereAmI) {
                properAdress = whereAmI;
              }
              var myData = $("#toHistoryState").html();
              var stateObj = {
                data: myData
              };
              //console.log("### makeHistory 4");
              if (newsStreamOnStartPage) {
                properAdress = currentURL;
              }
              //console.log("### makeHistory 5");
              history.replaceState(stateObj, "title: " + ownURL, properAdress);

              //console.log("### history.replaceState called, title="+"title: "+ownURL+", URL="+properAdress);

              whereAmI = null;
            }
          } else if (history.state !== null && history.state.data !== null && history.state.data !== newStateObj) {
            //console.log("### 6");

            newData = $("#toHistoryState").html(); // shouldn't it be before condition?
            newStateObj = {
              data: newData
            };
            history.replaceState(newStateObj, "modified title", currentURL);

            //console.log("### history.replaceState called");
          }
        }
        if (history.state === null) {
          //console.log("history.state is null");

          for (var i = 0; i < myAttributes.length; i += 1) {
            ownURL = myAttributes[i].getAttribute("data-ownurl");
            //console.log("ownURL: "+ownURL);
            //console.log("sub: "+sub);
            if (ownURL === sub && i === 1) {
              // add - if parent is active then makeHistory if not active then click()
              //myAttributes[i].click();
              makeHistory(myAttributes[i]);
              //console.log("first");
            }
            if (ownURL === sub && i !== 1) {
              // add - if parent is active then makeHistory if not active then click()
              myAttributes[i].click();
              //makeHistory(myAttributes[i]);
              //console.log("after first");
            }
            //console.log("ownURL.substring(ownURL.lastIndexOf('#')+1): "+ownURL.substring(ownURL.lastIndexOf("#")+1));
            if (ownURL.substring(0, 5) === "user/" && ownURL.substring(ownURL.lastIndexOf("#") + 1) === sub && i === 0) {
              //myAttributes[i].click();
              makeHistory(myAttributes[i]);
              //console.log("second");
            }
            if (ownURL.substring(0, 5) === "user/" && ownURL.substring(ownURL.lastIndexOf("#") + 1) === sub && i !== 0) {
              myAttributes[i].click();
              //makeHistory(myAttributes[i]);
              //console.log("after second");
            }
            if (sub === "" && ownURL.substring(0, 5) !== "user/" && i === 1) {
              // 1 set manually to load "Alles" by default
              //	myAttributes[i].click();
              makeHistory(myAttributes[i]);
              //console.log("third");
            }
            if (sub === "" && ownURL.substring(0, 5) === "user/" && i === 0) {
              // 0 set manually to load "Nur + profile" by default
              //	myAttributes[i].click();
              makeHistory(myAttributes[i]);
              //console.log("fourth");
            }
          }
        }
      })();
      // end of the part of the new pushState solution
    },
    initRemovableMedia: function initRemovableMedia() {
      $("ul.photoList").glRemovableMedia();
      // da Löschbestätigung s.u. nur entweder Foto oder Video aufgrund derselben ID unterstützt, ersteinmal Videos deaktiviert
      //$("ul.videoList").glRemovableMedia();
    },
    initNewsstream: function initNewsstream(value) {
      // part of the new pushState solution
      myValue = value; //
      whereAmI = null;
    },
    loadMoreEntries: function loadMoreEntries(param) {
      whereAmI = param; // end of the part of the new pushState solution
    },
    setHandlers: function setHandlers() {
      var $body = $("body");
      $body.on("click", ".mgl-news-content-more", function () {
        var $this = $(this);
        var $infos = $this.closest(".mgl-news-infos");
        $this.hide();
        $infos.find(".mgl-news-content-partOne").hide().end().find(".mgl-news-content-part2").css("display", "table").end().find(".mgl-news-reviewActions").show();
      });
      $body.on("click", ".mgl-news-content-less", function () {
        var $content = $(this).closest(".mgl-news-infos");
        $content.find(".mgl-news-content-part2").hide().end().find(".mgl-news-content-partOne").css("display", "table").end().find(".mgl-news-content-more").show().end().find(".mgl-news-reviewActions").hide();
      });
      $body.on("click", ".mgl-news-content-toggle", function () {
        var $this = $(this);
        var $infos = $this.closest(".mgl-news-infos");
        var $toggleElem = $infos.find(".mgl-news-content-part2");
        var showText = "Beitrag nicht mehr anzeigen ...";
        var hideText = "Beitrag anzeigen ...";
        if ($toggleElem.filter(":visible").size() > 0) {
          $this.text(hideText).attr("title", hideText);
          $toggleElem.hide();
        } else {
          $this.text(showText).attr("title", showText);
          $toggleElem.css("display", "table").show();
        }
      });
      $body.on("click", "a.ns-show-more", function () {
        var $this = $(this);
        var $infos = $this.closest(".mgl-news-infos");
        $this.hide();
        $infos.find("li.ns-data-more").show().end().find("a.ns-show-less").show();
      });
      $body.on("click", "a.ns-show-less", function () {
        var $this = $(this);
        var $infos = $this.closest(".mgl-news-infos");
        $this.hide();
        $infos.find("li.ns-data-more").hide().end().find("a.ns-show-more").show();
      });
    },
    tagging: {
      init: function init(obj) {
        this.nMaxNsLocalityKeys = obj.nMaxNsLocalityKeys;

        // Init tagging using glTaggingPlus plugin
        this.$tagList = $("#nsLocalities");
        this.$tagList.glTaggingPlus({
          tagText: $("#LocalityText"),
          separator: ", ",
          command: "modify"
        });

        // Set return handler of input to add tags on return
        this.setReturnHandler($("#txtNewNsLocality"));

        // Set behavior when button is clicked
        this.setButtonHandler($("#btnSubmitTag"), this.$tagList);
      },
      setReturnHandler: function setReturnHandler($element) {
        $element.on("keypress", function (e) {
          switch (e.keyCode) {
            case 13:
              // return
              $("#btnSubmitTag").click();
              e.preventDefault();
              break;
            default:
              break;
          }
        });
      },
      checkLocInputVisibility: function checkLocInputVisibility() {
        // Hide location inputfield if locationTags reached maxAmount
        if (this.getTagAmount() >= this.nMaxNsLocalityKeys) {
          this.hideLocInput();
        } else {
          this.showLocInput();
        }
        ////console.log("tagAmount = ", this.getTagAmount(), "| maxNsLocalityKeys =", this.nMaxNsLocalityKeys);
      },
      hideLocInput: function hideLocInput() {
        //console.log("hide locInput");
        $(".newNsLocality").hide();
      },
      showLocInput: function showLocInput() {
        //console.log("show locInput");
        $(".newNsLocality").show();
      },
      getTagAmount: function getTagAmount() {
        return $.fn.glTaggingPlus.getTagAmount();
      },
      updateNewsStreamHelp: function updateNewsStreamHelp() {
        var $helpWrap = $("#nsLocalitiesHelpWrap");
        var $selectedTagList = $("#nsLocalities li.selected");
        var helpText = "";
        $selectedTagList.each(function (i) {
          if (i !== 0) {
            if (i === $selectedTagList.length - 1) {
              helpText = helpText + " und ";
            } else {
              helpText = helpText + ", ";
            }
          }
          helpText = helpText + $(this).find("a.nsLocality").text();
        });
        if (helpText !== "") {
          $helpWrap.text(helpText);
        } else {
          $helpWrap.text("Du hast keine Stadt selektiert");
        }
      },
      setButtonHandler: function setButtonHandler($element, $tagList) {
        var me = this;
        $element.on("click", function () {
          //console.log("click!");
          var $input = $("#txtNewNsLocality"),
            sInputVal = $input.val(),
            // Store input-value
            $tagInList = $.fn.glTaggingPlus.isInTaglist(sInputVal, $tagList); // Return if tag already in list

          // clear input and blur for examples-values
          $input.val("").blur();
          if ($tagInList) {
            // get li from found anchor
            var $foundLi = $tagInList.parent();
            if (!$foundLi.hasClass("selected")) {
              $tagInList.click();
            }

            // hilight element
            $.fn.glTaggingPlus.hilightTag($foundLi);
            return;
          }
          $("li.newNsLocality", $tagList).before("<li class='popular added'><a class='nsLocality' href=''>" + $.escapeHTML(sInputVal) + "</a><a href='#' class='removeNsLocality' title='Ort entfernen'></a></li>");

          // Check visibility of location input field. Hide it if reaching maxAmound
          me.checkLocInputVisibility();

          // Add handler to last added tag
          var $lastLi = $("li.newNsLocality", $tagList).prev(),
            options = {
              tagText: $("#LocalityText"),
              command: "modify"
            };
          $.fn.glTaggingPlus.attachHandler($lastLi, options);
          options.command = "remove";
          $.fn.glTaggingPlus.attachHandler($lastLi.find("a.removeNsLocality"), options);

          // Hilight last added tag
          $lastLi.click();
          $.fn.glTaggingPlus.hilightTag($lastLi);
        });
      }
    },
    nsFilter: {
      setHandlers: function setHandlers() {
        var timeout = false;
        $("#reviewNsFilter, #commentNsFilter, #boardcommentNsFilter, #likeNsFilter, #boardNsFilter, #imageNsFilter, #badgeNsFilter, #checkinNsFilter, #otherNsFilter").on("click", function () {
          nsSearch(1000);
        });
        $("#filterNs").on("click", function () {
          $("#nsFilterForm").slideToggle();
          gol.trackEvent("showNewsstreamFilter");
          return false;
        });
        function nsSearch(delayInMillis) {
          clearTimeout(timeout);
          var $reviewNsFilter = $("#reviewNsFilter"),
            $commentNsFilter = $("#commentNsFilter"),
            $boardcommentNsFilter = $("#boardcommentNsFilter"),
            $likeNsFilter = $("#likeNsFilter"),
            $boardNsFilter = $("#boardNsFilter"),
            $imageNsFilter = $("#imageNsFilter"),
            $badgeNsFilter = $("#badgeNsFilter"),
            $checkinNsFilter = $("#checkinNsFilter"),
            $otherNsFilter = $("#otherNsFilter");
          var nsFilterMap = {
            reviewNsFilter: ($reviewNsFilter.attr("checked") ? "1" : "0") + "," + $reviewNsFilter.val(),
            commentNsFilter: ($commentNsFilter.attr("checked") ? "1" : "0") + "," + $commentNsFilter.val(),
            boardcommentNsFilter: ($boardcommentNsFilter.attr("checked") ? "1" : "0") + "," + $boardcommentNsFilter.val(),
            likeNsFilter: ($likeNsFilter.attr("checked") ? "1" : "0") + "," + $likeNsFilter.val(),
            boardNsFilter: ($boardNsFilter.attr("checked") ? "1" : "0") + "," + $boardNsFilter.val(),
            imageNsFilter: ($imageNsFilter.attr("checked") ? "1" : "0") + "," + $imageNsFilter.val(),
            badgeNsFilter: ($badgeNsFilter.attr("checked") ? "1" : "0") + "," + $badgeNsFilter.val(),
            checkinNsFilter: ($checkinNsFilter.attr("checked") ? "1" : "0") + "," + $checkinNsFilter.val(),
            otherNsFilter: ($otherNsFilter.attr("checked") ? "1" : "0") + "," + $otherNsFilter.val()
          };
          var favAct = $("li.mglSubNav-favoriteActivities"); // it doesn't exist when newsstream is on start page
          var includeHeroes;
          if (favAct.lengt > 0) {
            includeHeroes = $("li.mglSubNav-favoriteActivities").hasClass("active");
          } else {
            includeHeroes = true; // true, so when using filters the content won't switch to that from "nur meine" tab (which on start page isn't showed up at all)
          }
          gol.trackEvent("showNewsstreamFilter", "filterActivated");
          timeout = setTimeout(function () {
            gol.newsstream.extendNewsStream({
              nofPagesSkipped: -1,
              includeHeroes: includeHeroes,
              newNsLocality: undefined,
              command: undefined,
              chunked: undefined,
              lastTimestamp: undefined,
              lastSpan: undefined,
              nsFilterMap: nsFilterMap
            });
          }, delayInMillis);
        }
      },
      toggleVisibilityForOwnProfile: function toggleVisibilityForOwnProfile(isOwnUserprofile, includeHeroes) {
        // //console.log(isOwnUserprofile);
        // //console.log(includeHeroes);

        if (isOwnUserprofile) {
          if (includeHeroes === true) {
            // //console.log("Eigene Userseite: include heroes! includeHeroes =", includeHeroes);
            $("li.mglSubNav-myActivities").removeClass("active");
            $("li.mglSubNav-favoriteActivities").addClass("active");
            $("#nsFilterWrap").show();
            $("#ns-own-activities-help-wrap").hide();
            $("#ns-all-activities-help-wrap").show();
          } else {
            // //console.log("Eigene Userseite: NOT include heroes! includeHeroes =", includeHeroes);
            $("li.mglSubNav-favoriteActivities").removeClass("active");
            $("li.mglSubNav-myActivities").addClass("active");
            $("#nsFilterWrap").hide();
            $("#ns-all-activities-help-wrap").hide();
            $("#ns-own-activities-help-wrap").show();
          }
        } else {
          if (includeHeroes === true) {
            //console.log("Fremde Userseite - include heroes");
            $("li.mglSubNav-nsMyOwn").removeClass("active");
            $("li.mglSubNav-nsAll").addClass("active");
          } else {
            //console.log("Fremde Userseite - NOT include heroes");
            $("li.mglSubNav-nsAll").removeClass("active");
            $("li.mglSubNav-nsMyOwn").addClass("active");
          }
        }
      }
    },
    /**
     * @param options Optionen im Array
     *			nofPagesSkipped
     *			includeHeroes
     *			newNsLocality
     *			command
     *			chunked
     *			lastTimestamp
     *			lastSpan
     *			nsFilterMap
     */
    extendNewsStream: function extendNewsStream(options) {
      //console.log("about to load more stuff");
      var $loader, url, paramMap;

      // convert nofPagesSkipped to a boolean because of callbacks
      options.nofPagesSkipped = parseInt(options.nofPagesSkipped, 10);
      if (opt.ownUserPage) {
        url = "/meingolocal/extendNewsStream/";
      } else {
        url = "/user/extendNewsStream/" + opt.profileID + "/";
      }
      if (newsStreamOnStartPage) {
        paramMap = {
          nofPagesSkipped: options.nofPagesSkipped + 1,
          nofRequestedRecords: 5,
          newsStreamOnStartPage: true
        };
      } else {
        paramMap = {
          nofPagesSkipped: options.nofPagesSkipped + 1,
          nofRequestedRecords: 10
        };
      }
      if (options.command !== undefined) {
        paramMap.command = options.command;
      }
      if (options.lastTimestamp !== undefined) {
        paramMap.lastTimestamp = options.lastTimestamp;
      }
      if (options.lastSpan !== undefined) {
        paramMap.lastSpan = options.lastSpan;
      }
      if (options.nsFilterMap !== undefined) {
        paramMap = $.extend(paramMap, options.nsFilterMap);
      }
      if (options.includeHeroes !== undefined) {
        // convert includeHeroes to a boolean because of
        // different types (boolean from method call vs. string from browser back call)
        options.includeHeroes = options.includeHeroes.toString() === "true";
        paramMap.includeHeroes = options.includeHeroes;
      }
      if (options.newNsLocality !== undefined) {
        paramMap.newNsLocality = options.newNsLocality;
      }
      if (options.chunked !== undefined) {
        // convert chunked to a boolean because of
        paramMap.chunked = options.chunked.toString() === "true";
      }

      /*//console.log("### nofPagesSkipped=",options.nofPagesSkipped);
      //console.log("### includeHeroes=",options.includeHeroes);
      //console.log("### newNsLocality=",options.newNsLocality);
      //console.log("### command=",options.command);
      //console.log("### chunked=",options.chunked);
      //console.log("### lastTimestamp=",options.lastTimestamp);
      //console.log("### lastSpan=",options.lastSpan);
      //console.log("### nsFilterMap=", options.nsFilterMap);
      //console.log("extendNewsStream() called");*/

      //ajax request
      $.ajax({
        url: url,
        data: paramMap,
        cache: false,
        beforeSend: function beforeSend() {
          $("#nsErrorMessage").hide();
          $("#nsErrorNothingSelected").hide();
          $(".infoMsg_message").remove();
          if (options.nofPagesSkipped === -1) {
            $loader = $("#mgl-news-filterEntries-loader");
          } else {
            $loader = $("#mgl-news-olderEntries-loader");
          }
          $loader.show();
        },
        success: function success(txt) {
          gol.newsstream.nsFilter.toggleVisibilityForOwnProfile(opt.ownUserPage, options.includeHeroes);
          // just refresh the whole list if clicked on tab
          // or doing an ajax-browser-back-refresh (chunked == false)
          if (options.nofPagesSkipped === -1 || options.chunked === false) {
            //console.log("newNsFilterWrap 1");

            if (1) {
              // was $("li.refreshNs").length>0
              // why, what does it do?
              // now everything works, even after deleting locations while showing no news

              $("#mgl-newslist").html(txt);
              if (txt.indexOf("nsFilterWrap") !== -1) {
                $("#nsFilterMarker #nsFilterWrap").remove();
                $("#nsFilterMarker").append($("#nsFilterWrap"));

                //init nsFollowers
                $("#txtNewNsLocality").suggest("/suggestLocality/", {
                  onSelect: function onSelect() {
                    this.focus();
                    this.value = this.value;
                  }
                });

                // init tagging
                gol.newsstream.tagging.init({
                  nMaxNsLocalityKeys: opt.maxNsLocalityKeys
                });
                var $elem = $("#nsFollowers").find("li.popular");
                $elem.off("click");
                $elem.on("click", function () {
                  var $this = $(this);
                  $this.toggleClass("selected");
                  gol.newsstream.extendNewsStream({
                    nofPagesSkipped: -1,
                    includeHeroes: true,
                    newNsLocality: null,
                    command: "toggleIncludeHeroes"
                  });
                  this.blur();
                  return false;
                });
              }
            }
            if (txt.indexOf("unknownNsLocality") !== -1) {
              //last li in nsLocalities is not a valid locality
              var $lastLi = $("li.added").last();
              $("#nsErrorMessage").append("<strong class='infoMsg_message'>&bdquo;" + $lastLi.text() + "&rdquo; ist kein uns bekannter Ort</strong>").show();
              $lastLi.remove();
              gol.newsstream.tagging.checkLocInputVisibility();
              var $input = $("#txtNewNsLocality");
              $input.val($lastLi.text()).focus();
            }
          } else {
            // doing an li-replacement at the end of the existing list
            // with the new expanded (chunked) list
            var $replaceWrap = $("#extendNewsStreamReplaceMarker");
            $replaceWrap.replaceWith(txt);
          }
          if (txt.indexOf("nsNothingSelected") !== -1) {
            $("#nsErrorNothingSelected").append("<strong class='infoMsg_message'>Damit du hier Neuigkeiten siehst, wähle oben bitte etwas aus!</strong>").show();
          }

          // show news column in case it was hidden for browser-back-purposes
          // (hidden in the header)
          //$(".mgl-news-col").show();

          // TimeAgo erneut initialisieren
          //gol.timeago("update");

          // Initialize Lazyloading of Images after adding new content
          gol.lazyloading.init();

          // set tooltip
          // toDo: script mit dem in common.js kombinieren
          setTimeout(function () {
            //console.log("set tooltip (extend newsstream)")

            /* var $elements = $("a.avatarImage, a.toolTipLink");
            // remove existing handlers first
            $elements.off("click");
            		gol.tooltips.setupGolocalUser($elements);
            		$(".addInfoTooltipWide").cluetip(gol.tooltips.getAddInfoOptionsWide()); */

            gol.foundation.init();
            $(document).foundation();
          }, 500);
          if (gol.local.data.isValidUser) {
            gol.newsstream.initRemovableMedia();
          }
        },
        complete: function complete() {
          $loader.hide();

          // part of the new pushState solution
          var newStateObj, newData, currentURL;
          var ownURL;
          currentURL = window.location.href;
          //console.log("in complete");
          if (typeof myValue !== "undefined") {
            //console.log("typeof myValue !== \"undefined\"");
            if (myValue.nodeType === 1) {
              //console.log("myValue.nodeType === 1");
              ownURL = myValue.getAttribute("data-ownurl");
              var properAdress = "/meingolocal/#/" + ownURL + "/";
              if (ownURL !== null) {
                if (ownURL.substring(0, 5) === "user/") {
                  properAdress = "/" + ownURL + "/";
                }
              }
              if (whereAmI) {
                properAdress = whereAmI;
              }
              var myData = $("#toHistoryState").html();
              var stateObj = {
                data: myData
              };
              if (newsStreamOnStartPage) {
                properAdress = currentURL;
              }
              history.pushState(stateObj, "title: " + ownURL, properAdress);
              whereAmI = null;
              //console.log("original success");
              //console.log("history.pushState called");
              ////console.log("stateObj", stateObj);
            }
          } else if (1) {
            //console.log("replacing state");
            newData = $("#toHistoryState").html(); // shouldn't it be before condition?
            newStateObj = {
              data: newData
            };
            history.replaceState(newStateObj, "modified title", currentURL);
            //console.log("else in original success");
            //console.log("history.replaceState called");
          }
          // end of the part of the new pushState solution
        },
        error: function error(jqXHR, textStatus, errorThrown) {
          gol.gen.alert(errorThrown);
        }
      });
    },
    /**
    *
    * @param arrPathNames
    * @returns arrPathNames
    * @expose
    */
    /*exported getTrimmedPathNames */
    getTrimmedPathNames: function getTrimmedPathNames(arrPathNames) {
      $.each(arrPathNames, function (i) {
        arrPathNames[i] = arrPathNames[i] === "_" ? "" : arrPathNames[i];
      });
      return arrPathNames;
    }
  };
})(jQuery);

/**
 *
 * @param url
 * @param id
 * @returns
 * @expose
 */
/*exported submitReviewLayerForm */
function submitReviewLayerForm(url, id) {
  "use strict";

  gol.loader.show();
  var $reveal = $("body").append("<div class='reveal' id='mglReveal' data-reveal data-reveal data-reset-on-close='true'>" + "<div id='layerNewsstream'></div>" + "<button class='close-button' data-close aria-label='Close modal' type='button'><span aria-hidden='true'>&times;</span></button>" + "</div>");
  $.post(url, $("#reviewLayerForm_" + id).serialize(), function (txt) {
    $reveal.foundation();
    $("#layerNewsstream").html(txt);
    $("#mglReveal").foundation("open");
    gol.loader.hide();
  });
  return false;
}