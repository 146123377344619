"use strict";

// gl.extend-newsstream.js
//
// depends on gl.common.js
//
(function glExtendForum() {
  "use strict";

  gol.forum = {
    expandables: {
      init: function init() {
        // initialize expandable texts

        $(document).on("click", ".expandableText-more", function () {
          var $this = $(this);
          var $txtWrap = $this.closest(".expandableText");
          var $txtTruncated = $(".expandedText", $txtWrap);
          if ($txtTruncated.size() > 0) {
            $txtTruncated.show();
            $(".truncatedText", $txtWrap).hide();
          } else {
            $.get($this.attr("rel"), function (data) {
              $txtWrap.append(data);
              $(".truncatedText", $txtWrap).hide();
            });
          }
          return false;
        });
        $(document).on("click", ".expandableText-less", function () {
          var $txtWrap = $(this).closest(".expandableText");
          $txtWrap.find(".expandedText").hide().end().find(".truncatedText").show();
          return false;
        });
      } // END init
    },
    new_topic: {
      init: function init() {
        this.validation.init();
        setTimeout(function () {
          $("#topicText").trigger("focus");
        }, 500);
      },
      validation: {
        init: function init() {
          $("#frm_new_topic").validate({
            submitHandler: function submitHandler(form) {
              // Replace normal form-submit-behavior with ajax-form-submit

              var isValidUser = $(".isValidUser").val();
              if (isValidUser === "true") {
                var options = {
                  beforeSend: function beforeSend() {
                    gol.buttonloader.show($("#openDiscussionButton"));
                    $(".button.hollow").hide();
                    $("#openDiscussionButton").prop("disabled", true).css("cursor", "default");
                  },
                  success: function success(returnVal) {
                    if ($("#answer")) {
                      $("#answer").attr("value", "");
                    }
                    $("#infoMessage").fadeIn("500", function () {
                      var reloadLocation;
                      if (returnVal.match(/url\=/)) {
                        reloadLocation = returnVal.replace(/([\n\r]|.)*url\=([^\n\r]*)([\n\r]|.)*/, "$2");
                      }
                      gol.dialog.hideAndReload(reloadLocation);
                    });
                  },
                  error: function error(jqXHR, textStatus, errorThrown) {
                    $(".button.hollow").fadeIn();
                    gol.buttonloader.hide($("#openDiscussionButton"));
                    $("#openDiscussionButton").removeAttr("style").prop("disabled", false);
                    gol.gen.alert(errorThrown);
                  }
                };

                // Submit form
                $(form).ajaxSubmit(options);
              } else {
                //$(".backUrl").val(location.href);
                console.log("form =", form);
                $("#infoMessage").fadeIn("500", function () {
                  form.submit();
                  gol.loader.show();
                  gol.dialog.close();
                });
              }
            },
            onkeyup: false,
            onsubmit: true,
            onclick: false,
            rules: {
              forumShortName: "required",
              topicText: "required",
              entryText: "required"
            },
            messages: {
              forumShortName: "<strong>Kategorie: </strong> Bitte w&auml;hle eine Kategorie aus.",
              topicText: "<strong>Thema: </strong>Bitte gib ein Thema ein.",
              entryText: "<strong>Text: </strong>Bitte gib einen Text ein."
            },
            highlight: function highlight(element, errorClass) {
              $(element).fadeOut(function () {
                $(element).addClass(errorClass).fadeIn();
              });
            },
            errorContainer: $("#newTopicErrorBox"),
            errorLabelContainer: $("#newTopicErrorBox ul"),
            errorClass: "invalid",
            wrapper: "li"
          });
          this.ensureErrorVisibility();
        },
        ensureErrorVisibility: function ensureErrorVisibility() {
          var self = this;
          // Check if form is valid and scroll to error message if not
          // if the form is o.k. add form-action and submit it
          $("#openDiscussionButton").on("click", function () {
            if ($("#frm_new_topic").validate().form() === false) {
              setTimeout(function () {
                self.scrollToErrorMessage();
              }, 500);
            }
          });
        },
        scrollToErrorMessage: function scrollToErrorMessage() {
          $("div.mainDiscussionContent").scrollTo("a#endOfContent", {
            axis: "y",
            easing: "easeOutBounce",
            duration: 700
          });
        }
      }
    },
    add_entry: {
      init: function init() {
        this.validation.init();
      },
      validation: {
        init: function init() {
          $("#frm_add_forum_entry").validate({
            submitHandler: function submitHandler(form) {
              var isValidUser = $(".isValidUser").val();

              //Replace normal form-submit-behavior with ajax-form-submit
              if (isValidUser === "true") {
                var options = {
                  success: function success(returnVal) {
                    if ($("#answer")) {
                      $("#answer").attr("value", "");
                    }
                    if (returnVal.match(/^[\n\r\s]*redirectTo\=.+[\n\r\s]*$/)) {
                      var redirectUrl = returnVal.replace(/([\n\r]|.)*redirectTo\=(.*)([\n\r]|.)*/, "$2");
                      gol.gen.reload(redirectUrl);
                    } else {
                      var reloadLocation = $("#reloadUrl").text();
                      gol.gen.reload(reloadLocation);
                    }
                  },
                  error: function error(jqXHR, textStatus, errorThrown) {
                    gol.gen.alert(errorThrown);
                  }
                };

                // Submit form
                $(form).ajaxSubmit(options);
              } else {
                //$(".backUrl").val(location.href);

                form.submit();
              }
            },
            onkeyup: false,
            onfocusout: false,
            onsubmit: true,
            onclick: false,
            rules: {
              entryText: "required"
            },
            messages: {
              entryText: "<strong>Text: </strong>Bitte gib einen Text ein."
            },
            highlight: function highlight(element) {
              $(element).fadeOut(function () {
                $(element).fadeIn();
              });
            },
            errorContainer: $("#addEntryErrorBox"),
            errorLabelContainer: $("#addEntryErrorBox ul"),
            errorClass: "invalid",
            wrapper: "li"
          });
        }
      }
    }
  };
})(jQuery);