"use strict";

/**
 * gl.extend.js
 *
 * @deprecated ACHTUNG!!! DEPRECATED, BITTE NEUE FUNKTIONEN IN SEITENTYPSPEZIFISCHE gl.extend-SEITENTYP.js AUSLAGERN!!!
 *
 * depends on gl.common.js
 */
var golExtended = golExtended || {};
(function glExtend() {
  "use strict";

  golExtended = {
    initExtended: function initExtended() {
      //console.log("gol.init() called");

      // Use Traditional serializing of objects
      // This means {foo: ["bar", "baz"]} is still serialized as foo=bar&foo=baz and not foo[]=bar&foo[]=baz
      // If this setting is changed all get calls with parameter lists must be changed to
      // $.get(link,jQuery.param( {'itemList': ids}, true ), ...
      // instead of
      // $.get(link,{'itemList': ids}, ...
      // testweise deaktivieren jQuery.ajaxSettings.traditional = true;

      // Set sliding for assigning photos
      //if($("#glMap").size() <= 0 && $("div.floatedSection").size() > 0) this.map.floating.init();
    },
    npw: {
      validation: function validation() {
        $("#email").on("keyup", function () {
          gol.registration.checkEMail($("#email"), $("#frm_snpw"));
        });
        $("#frm_snpw").validate({
          submitHandler: function submitHandler(form) {
            // Replace normal form-submit-behavior with ajax-form-submit
            gol.loader.show();

            //console.log("Submit form npw");
            form.submit();
          },
          errorClass: "error"
        });
      }
    },
    userprofile: {
      init: function init() {
        // Init Form-Validation
        this.validation.init();
      },
      validation: {
        init: function init() {
          $("#frm_userprofile").validate({
            ignore: ".ignore",
            messages: {
              picUpload: "<strong>Bitte best&auml;tige, dass Du berechtigt bist, das Foto zu ver&ouml;ffentlichen.</strong>"
            },
            rules: {
              // simple rule, converted to {required:true}
              picUpload: "required"
            },
            errorContainer: $("#errorMessageBox"),
            errorLabelContainer: $("#errorMessageBox ul"),
            errorClass: "invalid",
            wrapper: "li"
          });
        }
      }
    },
    userPreferences: {
      init: function init() {
        $("#userProfileForm").glForms();
        function sendUnblockUserAction(actionHref) {
          $.ajax({
            url: actionHref,
            type: "POST",
            success: function success(returnVal) {
              if (returnVal.match(/^\s*ok[\s\r\n]*$/)) {
                gol.gen.reload();
              }
            },
            error: function error(jqXHR, textStatus, errorThrown) {
              gol.gen.alert(errorThrown);
            },
            complete: function complete() {
              $("body").css("cursor", "");
            }
          });
        }
        $(".requestUnblockUser").on("click", function () {
          sendUnblockUserAction(this.href);
          return false;
        });
      }
    }
  };
})(jQuery);

// extend variable gol with extended objects
$.extend(gol, golExtended);
$(document).ready(function () {
  "use strict";

  golExtended.initExtended();
});