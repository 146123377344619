"use strict";

// gl.extend-sidebar.js
//
// depends on gl.common.js
//
(function glExtendMap() {
  "use strict";

  var $this;
  gol.map = {
    init: function init() {
      //console.log("gol.map init() called");

      this.setObjects();
      if (gol.local.data.eventTrackerCategory !== "MapDetails") {
        // don't do display stuff for map details
        this.setMapDisplay();
        this.setListDisplay();
      }
      this.floating.init();
      if (Foundation.MediaQuery.is("medium up")) {
        // no hover for mobile
        this.hover.init();
      }
      this.mapActions.init();
      this.messages.init();

      // set initialization flag
      this.booInitialized = true;
    },
    setObjects: function setObjects() {
      //console.log("setObjects() called");
      this.$window = $(window);
      this.$document = $(document);
      this.$sideBar = $("#sideBar");
      this.$map = $("#glMap");
      this.$scrollArea = $("div.floatedSection");
    },
    setMapDisplay: function setMapDisplay() {
      //console.log("setMapDisplay() called");

      // for small mobile screens set map to 250px (because Google (or Foundation?) is doing that automatically)
      if (Foundation.MediaQuery.is("medium up")) {
        this.$map.css("height", "250px");
      } else {
        this.$map.css("height", "500px");
      }
      $(".mapActions").show();
      $(".dontMoveWrapper").show();
    },
    showInactiveMap: function showInactiveMap() {
      if (Foundation.MediaQuery.is("medium up")) {
        $(".glmap--inactive").show();
      }
    },
    setListDisplay: function setListDisplay() {
      //showMarkers, but not for mobile (small displays)		
      $(".listingContent", ".listEntry").addClass("activeMap");
    },
    showMap: function showMap(elem) {
      //console.log("showMap(", elem, ") called");
      //this.currMapToggler = sessionStorage.getItem('currMapToggler', elem);

      // check if map is still there (may be removed after ajax reload)
      // and append maps html template again if not
      if ($(".floatedSection").length === 0) {
        var sMapsTemplate = "<div class=\"floatedSection\"><div class=\"sideBarFuncHolder\"><div id=\"glMap-section\" aria-hidden=\"true\"><div id=\"glMap\" style=\"width: 100%; display:block;\"></div></div></div></div>";
        this.$sideBar.append(sMapsTemplate);
        // set initialized-flag back to false so the map will be initialized again
        this.booInitialized = false;
      }
      if (!this.booInitialized) {
        GlMap.initialize();
        gol.map.init();
      }

      // ensures that hover event will be called to open tooltip in map
      var $thisMapHolder = $(elem).closest(".listEntry");
      $thisMapHolder.mouseleave().mouseenter();
      if (this.$scrollArea.is(":visible") && this.currMapToggler !== elem || !this.$scrollArea.is(":visible")) {
        // only pin map to location for mobile screens
        if (Foundation.MediaQuery.is("small only")) {
          //console.log("small display");
          //console.log("Karte anzeigen");

          this.currMapToggler = elem;
          this.$scrollArea.hide();
          this.pinMapToLocation(elem);
          GlMap.highlightOn($thisMapHolder);
          this.$scrollArea.slideDown(300);
          //$("#chkMapDontFollow").prop('checked', true);
          this.floating.disable();
          $(".dontMoveWrapper").hide();
          $(".mapSizeSwitchWrapper").hide();
        }
      } else {
        //console.log("Karte ausblenden");
        this.hideMap();
      }
    },
    hideMap: function hideMap() {
      this.$scrollArea.slideUp(300);
    },
    pinMapToLocation: function pinMapToLocation(elem) {
      //var $mapHolder = $(elem).closest(".listingContent");
      var $elem = $(elem);
      var $mapHolder = $elem.siblings("address");

      // set current map holder
      this.setCurrentMapHolder($elem);

      // grab map and put it into list-entry
      this.$scrollArea.insertAfter($mapHolder);
    },
    unpinMapFromLocation: function unpinMapFromLocation() {},
    setCurrentMapHolder: function setCurrentMapHolder($elem) {
      this.$currentMapHolder = this.findCurrentMapHolder($elem);
    },
    findCurrentMapHolder: function findCurrentMapHolder($elem) {
      return $elem.closest(".listEntry");
    },
    getCurrentMapHolder: function getCurrentMapHolder() {
      return this.$currentMapHolder;
    },
    hover: {
      init: function init() {
        var booDoMapFunctions = $("#glMap").length > 0;

        // Take care of
        $("ol.locList .listEntry, ol.locationsList .listEntry, ol.reviewList .listEntry, ul.listAdEntry .listEntry, " + "ul.listEntriesWrapper .listEntry, li.sponsoredWrapper .listEntry, #checkinsList .checkin-li").on("mouseenter", function () {
          // user highlightOn() from gl.map.js only if #glMap is available
          var $this = $(this);
          if (booDoMapFunctions && $("#glMap").css("display") === "block") {
            GlMap.highlightOn($this);
          }
          // otherwise only change the background
          else {
            $this.css("background-color", "#f6f7e8");
          }

          //$this.find("ul.listingActions").css("visibility", "visible");
        }).on("mouseleave", function () {
          var $this = $(this);
          if (booDoMapFunctions && $("#glMap").css("display") === "block") {
            GlMap.highlightOff($this);
          } else {
            $this.css("background-color", "");
          }

          //$this.find("ul.listingActions").css("visibility", "hidden");
        });
      }
    },
    mapHeight: {
      init: function init() {
        // called in gl.map.js when map was initialized

        // assign objects
        this.$sideBar = gol.map.$sideBar;
        // only calculate and adjust height
        // if sideBar available (result- and details-page
        // but not on start page)
        if (!this.$sideBar || this.$sideBar.length <= 0) {
          return;
        }
        this.$window = gol.map.$window;
        this.$document = gol.map.$document;
        this.$scrollArea = gol.map.$scrollArea;
        this.$map = gol.map.$map;
        this.$mapActions = $("div.mapActions", this.$sideBar);
        this.$sideBarNav = $("#sideBarNav");

        // Check first first DIV within glMap
        // If this first DIV has a height of 100% we assume
        // that it"s the right DIV and contains the googleMap
        this.$mapFirstChild = this.$map.children("div:first");
        this.$mapFirstChildIsMap = this.$mapFirstChild.css("height") === "100%";

        // set max and min sizes of map
        this.nMaxHeight = 600;
        this.nMinHeight = 250;
        this.setHandlers();
        this.adjustMap();
      },
      setHandlers: function setHandlers() {
        var $this = this;
        // adust height of map on every resize
        this.$window.on("resize", function () {
          $this.adjustMap();
        }).on("scroll", function () {
          clearTimeout(gol.map.mapHeight.oTimeout);
          gol.map.mapHeight.oTimeout = setTimeout(function () {
            //console.log("doResizing!");
            $this.$window.trigger("resize");
            //alert("jQuery.browser.msie = " + jQuery.browser.msie + "| $.browser.version.substr(0,1) = " + $.browser.version.substr(0,1));

            // IE6 has a problem with the first DIV within glMap which will be created from Google
            // and has a height of 100%. This height is not always adjusted when page is scrolled.
            // The Fix is to set the height of this first DIV (that contains the map) to the height
            // of our adjusted glMap-DIV.
            if ($this.$mapFirstChildIsMap && $.browser.msie && parseInt($.browser.version, 10) <= 8) {
              $this.$mapFirstChild.css("height", $this.$map.height());
            } /*else {
              // kein IE6/7
              }*/
          }, 400);
        });
      },
      adjustMap: function adjustMap() {
        //console.log("adjustMap() called");

        this.nWindowHeight = this.$window.height();

        //this.nScrollAreaHeight = this.$scrollArea.height();
        this.nMapHeight = this.$map.height();
        /*console.log("this.$sideBarNav.innerHeight() =", this.$sideBarNav.innerHeight(), " | height = ", 
        		this.$sideBarNav.height(), "this.$mapActions.innerHeight() = ", this.$mapActions.innerHeight(), " | height =", 
        		this.$mapActions.height());*/

        // MapActions and SideBar-Navigation are not available when user is logged out
        var nMapActionsHeight = this.$mapActions.length > 0 ? this.$mapActions.height() : 0;
        var nSideBarNavHeight = this.$sideBarNav.length > 0 ? this.$sideBarNav.innerHeight() : 0;
        this.nMapAreaHeight = this.nMapHeight + nMapActionsHeight + nSideBarNavHeight + 30;

        // if sideBar with sliding content -> take this
        // otherwise just take top position of map (e.g. in details page)
        var nScrollTop = this.$window.scrollTop();
        var nTopPos = this.$sideBar.length > 0 ? this.$sideBar.offset().top - nScrollTop : this.$map.offset().top - nScrollTop;
        nTopPos = nTopPos < 0 ? 0 : nTopPos;
        var nDiff = this.nWindowHeight - (nTopPos + this.nMapAreaHeight);
        var nNewMapHeight = this.nMapHeight + nDiff;
        if (nNewMapHeight < this.nMaxHeight && nNewMapHeight > this.nMinHeight) {
          //console.log("adjust Map: nNewMapHeight = ", nNewMapHeight);
          this.$map.css("height", this.nMapHeight + nDiff);
        } else if (nNewMapHeight <= this.nMinHeight) {
          //console.log("kleiner 200: nNewMapHeight = ", nNewMapHeight, "--> setze auf Min-Wert");
          this.$map.css("height", this.nMinHeight);
        } else if (nNewMapHeight >= this.nMaxHeight) {
          //console.log("größer 500: nNewMapHeight = ", nNewMapHeight, "--> setze auf Max-Wert");
          this.$map.css("height", this.nMaxHeight);
        }

        /*console.log( "nDiff = this.nWindowHeight - (nTopPos + this.nMapAreaHeight): ", this.nWindowHeight, "- (", nTopPos, "+", 
        		this.nMapAreaHeight,") =", this.nWindowHeight - (nTopPos + this.nMapAreaHeight) );*/
      }
    },
    floating: {
      init: function init() {
        // don't float on mobile (small) devices
        if (Foundation.MediaQuery.is("small only")) {
          return;
        }
        this.$scrollArea = gol.map.$scrollArea;
        if (this.$scrollArea.length <= 0) {
          return;
        }
        this.initMovement();
        this.setLock();
      },
      setLock: function setLock() {
        var oClass = this;
        this.booSelected = false;

        // set enabled-status
        // set an object with the property {cookie:0} to ensure that cookie is not set the first time
        if (this.isEnabled()) {
          this.enable({
            cookie: 0
          });
        } else {
          this.disable({
            cookie: 0
          });
        }

        // Disable map-movement, when clicked
        $("#chkMapDontFollow").on("click", function () {
          //console.log("chkMapDontFollow click");

          if (this.checked) {
            oClass.disable();
          } else {
            oClass.enable();
          }
        });

        // disable map-movement when mouseover
        this.$scrollArea.on("mouseenter", function () {
          oClass.booSelected = true;
        }).on("mouseleave", function () {
          oClass.booSelected = false;
        });
      },
      enable: function enable(obj) {
        this.booNoFollow = false;
        //console.log("chkMapDontFollow enable");

        if (!obj || obj.cookie) {
          /* jshint bitwise:false */
          gol.settings.setCookie(gol.settings.getCookie() ^ 2);
        }
        $("#chkMapDontFollow").check("off");
      },
      disable: function disable(obj) {
        this.booNoFollow = true;
        if (!obj || obj.cookie) {
          /* jshint bitwise:false */
          gol.settings.setCookie(gol.settings.getCookie() ^ 2);
        }
        $("#chkMapDontFollow").check();
      },
      isEnabled: function isEnabled() {
        /* jshint bitwise:false */

        //console.log("cookie =", gol.settings.getCookie(), "isEnabled(): ", gol.settings.getCookie()&2);

        // check cookie
        return gol.settings.getCookie() & 2;
      },
      initMovement: function initMovement() {
        //console.log("initMovement() called");

        this.nTop = 0;
        this.nMarginTop = 0;
        this.w = $(window);
        this.d = $(document);
        this.nWindowHeight = this.w.height();
        this.nDocumentHeight = this.d.height();
        this.$slidingMapRef = $(".slidingMapRef");
        $(window).on("scroll", function () {
          clearTimeout(gol.map.floating.oScrollTimeout);
          gol.map.floating.oScrollTimeout = setTimeout(function () {
            gol.map.floating.setMovement();
          }, 600);
        });

        // call scroll() to activate floating when map was turned on so it moves into view if necessary
        $(window).trigger("scroll");

        // setInterval when no scrolling is available
        /*else {
        	var myInterval = setInterval( function(){
        		oClass.setMovement(oClass);
        	}, 700 );
        }*/
      },
      setMovement: function setMovement() {
        //console.log("setMovement() called");
        this.w = $(window);
        this.nTop = this.w.scrollTop();
        this.$scrollArea = gol.map.$scrollArea;
        if (this.booSelected || !gol.map.floating.isEnabled() || this.nTop === this.nCurrentTop) {
          return;
        }
        this.nCurrentTop = parseInt(this.$scrollArea.css("marginTop"), 10);
        this.nContentHeight = gol.map.floating.$slidingMapRef.height();
        this.nScrollAreaOffset = this.$scrollArea.offset().top - this.nCurrentTop;
        this.nScrollAreaHeight = this.$scrollArea.height();
        this.nMaxPos = this.nContentHeight - this.nScrollAreaHeight;
        //console.log("contentHeight:", this.nContentHeight, "- scrollAreaHeight: ", this.nScrollAreaHeight, "=", this.nMaxPos);

        //console.log("nTop (", this.nTop, ") + nScrollAreaHeight (", this.nScrollAreaHeight, ") [", this.nTop + this.nScrollAreaHeight, "] > nContentHeight (", this.nContentHeight, ")");
        // set to max position if specified contentHeight is smaller than map + scrollTop - mapOffset
        if (this.nTop + this.nScrollAreaHeight - this.nScrollAreaOffset > this.nContentHeight) {
          this.nMarginTop = this.nMaxPos;
          // normal move
        } else if (this.nTop > this.nScrollAreaOffset - this.w.scrollTop()) {
          this.nMarginTop = this.nTop - this.nScrollAreaOffset;
          // set to min position (top)
        } else if (this.nTop <= this.nScrollAreaOffset) {
          this.nMarginTop = 0;
        }

        /*console.log("nTop =", this.nTop, "| nScrollAreaHeight=", this.nScrollAreaHeight, "| nDocumentHeight=", 
        		this.nDocumentHeight, "nScrollAreaOffset=", this.nScrollAreaOffset, "| MarginTop =", this.nMarginTop);*/

        // animate to calculated position - but only if positiv
        this.$scrollArea.animate({
          "marginTop": this.nMarginTop > 0 ? this.nMarginTop : 0
        }, 500);
        this.nCurrentTop = this.nTop;
      },
      resetPosition: function resetPosition() {
        if (this.$scrollArea) {
          this.$scrollArea.css("marginTop", 0);
        }
      }
    },
    mapActions: {
      init: function init() {
        var $newRegionSearchWrapper = $("div.newRegionSearchWrapper", "#sideBar");
        $("a", $newRegionSearchWrapper).on("click", function () {
          $newRegionSearchWrapper.find("a").removeClass("activeLink");
          $(this).addClass("activeLink");
        });
      }
    },
    messages: {
      init: function init() {
        this.$map = $("#glMap");
        this.htmlWrapper = "<div class='mapMessage'>" + "<a href='#' title='Meldung schließen' class='singleClose'><span>Schließen</span></a>" + "<div class='message'></div>" + "</div>";
        this.htmlZoomOutMessage = "Es gibt Locations außerhalb des sichtbaren Bereichs<br />" + "<a href='#' onclick='return GlMap.displayCategory();' title='Karten-Maßstab verkleinern, um die nicht sichtbaren Locations anzuzeigen'>" + "<strong>Herauszoomen und Locations anzeigen</strong></a>";
        //this.show();

        this.setHandlers();
      },
      setHandlers: function setHandlers() {
        $this = this;
        $(document).on("click", "a.singleClose", function () {
          $this.hide();
          return false;
        });
      },
      show: function show() {
        //console.log("messages.show() called");
        if (!this.$wrapper) {
          this.$wrapper = $(this.htmlWrapper).appendTo(this.$map);
          this.$wrapper.find("div.message").html(this.htmlZoomOutMessage);
        }
        this.$wrapper.fadeIn(500);
      },
      hide: function hide() {
        //console.log("messages.hide() called");
        if (this.$wrapper) {
          this.$wrapper.fadeOut(300);
        }
      }
    }
  };
})(jQuery);
$(function () {
  "use strict";

  //gol.map.init();
});