"use strict";

window.fbAsyncInit = function () {
  "use strict";

  if (FB && FB.Event && FB.Event.subscribe) {
    FB.Event.subscribe("edge.create", function (targetUrl) {
      ga("send", "social", "Facebook", "Like", targetUrl);
    });
    FB.Event.subscribe("edge.remove", function (targetUrl) {
      ga("send", "social", "Facebook", "Unlike", targetUrl);
    });
    FB.Event.subscribe("edge.send", function (targetUrl) {
      ga("send", "social", "Facebook", "Send", targetUrl);
    });
  }
};

/* Twitter not neccessary and working :)
 * 
 * window.twttr.ready(function (twttr) {
    //event bindings
    twttr.events.bind("tweet", trackTwitter);
});


function trackTwitter(intent_event) {
    if (intent_event) {
      var opt_pagePath;
      if (intent_event.target && intent_event.target.nodeName == "IFRAME") {
            opt_target = extractParamFromUri(intent_event.target.src, "url");
      }
      ga("send", "social", "Twitter", "Tweet", opt_pagePath);
    }
  }

*/