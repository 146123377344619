"use strict";

/**
 * GOLOCAL Social LIBRARY für Social Login, wie Facebook, Google und andere Dienste
 * Für facebook:
 * <div id="fb-root"></div> muss im bottom.jspf eingebunden sein
**/

var fbAccessToken,
  fbUid,
  fbPermissions = "email,publish_actions",
  auth2;
window.fbAsyncInit = function () {
  "use strict";

  FB.init({
    appId: gol.local.data.FBappId,
    status: true,
    cookie: true,
    xfbml: true,
    oauth: true
  });
};
gol.social = {
  fbauth: function fbauth(action, lda, nvsid, nvm, sreqid, combinedMode) {
    "use strict";

    /* jshint devel:true */
    var currTime = new Date().getTime(),
      width = 520,
      height = 300,
      left = window.innerWidth / 2 - width / 2,
      top = window.innerHeight / 2 - height / 2,
      paramMap = {
        lda: lda,
        nvsid: nvsid,
        nvm: nvm,
        sreqid: sreqid,
        combinedMode: combinedMode
      },
      fbAuthUrl = "?t=" + currTime;
    if (gol.local.data.coop !== undefined) {
      paramMap.coop = gol.local.data.coop;

      //show warning if third party cookies are deactivated, because fb server login doesn't work then (no session!)
      if (!gol.storage.areCookiesAvailable()) {
        var errorTxt = "" + "Die Cookies sind in diesem Browser nicht aktiviert.</br>" + "Um sich mit Facebook einloggen zu können, müssen die Sicherheitseinstellungen geändert werden.</br>" + "Eine <a target='_blank' onclick='gol.trackEvent('CookieSupportSite');' rel='nofollow' " + "href='http://support.google.com/accounts/bin/answer.py?hl=de&answer=61416'>" + "ausführliche Anleitung</a> von Google unterstützt Sie dabei.</p>";
        $("<div id='fbCookieError'></div>").html(errorTxt).dialog({
          title: "Cookies erforderlich",
          modal: true,
          width: 320,
          height: 150,
          dialogClass: "standardShadow",
          resizable: true
        });
        return false;
      }
    }
    if (lda !== undefined) {
      fbAuthUrl = fbAuthUrl + "&" + $.param(paramMap);
    }
    if (action !== undefined && action !== null && action !== "") {
      gol.trackEvent("fb" + action);
      switch (action) {
        case "login":
          fbAuthUrl = "/loginfba/" + fbAuthUrl;
          break;
        case "retry":
          fbAuthUrl = "/loginfba/retry/" + fbAuthUrl;
          break;
        case "link":
          fbAuthUrl = "/loginfba/" + action + "/" + fbAuthUrl;
          break;
        default:
          gol.gen.alert("Keine Aktion angegeben");
          break;
      }
    } else {
      return false;
    }
    window.open(fbAuthUrl, "_blank", "dependent=yes, location=no, menubar=no, resizable=yes, scrollbars=no, status=no, toolbar=no, width=" + width + ", height=" + height + ", left=" + left + ", top=" + top, true);
    try {
      smartlook("track", "loginType", {
        "type": "Facebook"
      });
    } catch (e) {
      //nothing todo
    }
    return false;
  },
  initGoogleApi: function initGoogleApi(action, lda, nvsid, nvm, sreqid, combinedMode) {
    "use strict";

    gapi.load("auth2", function () {
      auth2 = gapi.auth2.getAuthInstance();
      if (auth2 === undefined || auth2 === null) {
        auth2 = gapi.auth2.init({
          //client_id: "642140004148-9itmbih56264b54s0aivmioagcsc1gp5.apps.googleusercontent.com", <- key for GoogleSignin project
          client_id: "69120372849-boolav39b5cllt5q2lj21suec0knuh4o.apps.googleusercontent.com",
          //new key for golocal project
          cookiepolicy: "single_host_origin",
          scope: "profile email"
        });
      }
      if ($("#customGoogleSigninBtn").length > 0) {
        gol.social.attachSignin(document.getElementById("customGoogleSigninBtn"), action, lda, nvsid, nvm, sreqid, combinedMode);
      } else if ($(".customGoogleSigninBtn").length > 0) {
        // iterate through every occurrence of the button
        document.querySelectorAll(".customGoogleSigninBtn").forEach(function (oButton) {
          gol.social.attachSignin(oButton, action, lda, nvsid, nvm, sreqid, combinedMode);
        });
      }
      gol.social.initGoogleApi.booGapiLoaded = true;
    });
  },
  attachSignin: function attachSignin(element, action, lda, nvsid, nvm, sreqid, combinedMode) {
    "use strict";

    this.signInAttached = true;
    var signInOptions = new gapi.auth2.SigninOptionsBuilder({
      "fetch_basic_profile": true,
      "scope": "profile email",
      "prompt": "select_account" /*, "app_package_name": "de.golocal" */
    });
    auth2.attachClickHandler(element, signInOptions, function (googleUser) {
      gol.social.onSignIn(googleUser, action, lda, nvsid, nvm, sreqid, combinedMode);
    }, function (error) {
      console.log(JSON.stringify(error, undefined, 2));
    });
  },
  onSignIn: function onSignIn(googleUser, action, lda, nvsid, nvm, sreqid, combinedMode) {
    "use strict";

    //console.log("action="+action+", lda="+lda+", nvsid="+nvsid+", nvm="+nvm+", sreqid=" + sreqid + ", combinedMode="+combinedMode);
    var googleAction = action;
    var googleParamMap = {
      lda: lda,
      nvsid: nvsid,
      nvm: nvm,
      sreqid: sreqid,
      combinedMode: combinedMode
    };
    if (gol.local.data.coop !== undefined) {
      googleParamMap.coop = gol.local.data.coop;
    }
    gol.loader.show();

    //console.log("coop="+googleParamMap.coop);

    // Useful data for your client-side scripts:
    //var profile = googleUser.getBasicProfile();
    //console.log("ID: " + profile.getId()); // Don't send this directly to your server!
    //console.log("Name: " + profile.getName());
    //console.log("Image URL: " + profile.getImageUrl());
    //console.log("Email: " + profile.getEmail());

    // The ID token you need to pass to your backend:
    var id_token = googleUser.getAuthResponse().id_token;
    //console.log("ID Token: " + id_token);

    var googleAuthUrl = "/googleauth/";
    if (googleAction !== undefined && googleAction !== null && googleAction !== "" && googleAction === "link") {
      googleAuthUrl = googleAuthUrl + googleAction + "/";
    }

    //console.log("googleAuthUrl: " + googleAuthUrl);

    googleParamMap.idTokenString = id_token;
    $.ajax({
      type: "POST",
      dataType: "json",
      url: googleAuthUrl,
      data: googleParamMap,
      xhrFields: {
        withCredentials: true
      }
    }).done(function (json) {
      //console.log("success");
      //console.log("responseCode=", json.responseCode);
      //console.log("socialLoginUrl=", json.socialLoginUrl);

      try {
        smartlook("track", "loginType", {
          "type": "Google"
        });
      } catch (e) {}
      if (json !== null && json.responseCode !== null && json.responseCode === "OK") {
        if (googleParamMap.coop !== undefined && googleParamMap.coop !== null && googleParamMap.coop !== "null" && googleParamMap.coop !== "") {
          var $replaceElem = $("#golocalMain");
          $.ajax({
            type: "GET",
            url: json.socialLoginUrl,
            xhrFields: {
              withCredentials: true
            }
          }).done(function (txt) {
            if (txt.match(/id\=\"?reviewAchievementsWindow\"?/)) {
              gol.dialog.close();
              gol.dialog.open("Herzlichen Glückwunsch", "?modal=true&amp;height=500&amp;width=840&amp;reloadonclose=true", txt);
            } else {
              //console.log("replace main case");

              $replaceElem.html(txt);
            }
          }).fail(function () {
            console.log("error");
          }).always(function () {
            console.log("complete");
          });
        } else {
          //console.log("reload case");

          location.href = json.socialLoginUrl;
        }
      } else if (json !== null && json.responseCode !== null) {
        console.log("token validation failed, responseCode=" + json.responseCode);
      } else {
        console.log("token validation failed");
      }
    }).fail(function () {
      console.log("error");
    }).always(function () {
      console.log("complete");
    });
  },
  login: function login(action, encryptedId) {
    "use strict";

    var socialUrl = "/socialLogin/",
      paramMap = {};
    if (action !== undefined && action !== null && action !== "") {
      if (action === "link") {
        socialUrl = socialUrl + "link/";
      }
    } else {
      return false;
    }
    paramMap.encryptedId = encryptedId;
    if (gol.local.data.coop !== undefined) {
      var $replaceElem = $("#golocalMain");
      gol.loader.show();
      $.ajax({
        type: "POST",
        url: socialUrl,
        data: paramMap,
        success: function success(txt) {
          $replaceElem.html(txt);
        },
        xhrFields: {
          withCredentials: true
        },
        error: function error(jqXHR, textStatus, errorThrown) {
          gol.gen.alert(errorThrown);
        }
      });
      return false;
    } else {
      gol.gen.reload(socialUrl + "?" + $.param(paramMap));
    }
  },
  link: function link(isCoop) {
    "use strict";

    if (isCoop) {
      gol.loader.show();
      var linkUrl = "/socialLink/confirm/";
      $.ajax({
        type: "POST",
        url: linkUrl,
        data: $("#socialLinkForm").serialize(),
        success: function success(txt) {
          $("#golocalMain").html(txt);
        },
        xhrFields: {
          withCredentials: true
        },
        error: function error(jqXHR, textStatus, errorThrown) {
          gol.gen.alert(errorThrown);
        }
      });
    }
    return false;
  },
  unlink: function unlink(loginType) {
    "use strict";

    var paramMap = {
      loginType: loginType
    };
    $.ajax({
      url: "/unlink/",
      type: "POST",
      data: paramMap,
      success: function success() {
        if (loginType === "verimi") {
          $("#userProfileUnlinkVerimi").html("Die Verknüpfung wurde gelöst.<br/>Wenn Du Dich erneut verknüpfen möchtest, logge Dich bitte aus und klicke beim Login auf den Verimi-Button.");
        } else {
          gol.gen.reload();
        }
      }
    });
    return false;
  },
  opengraph: {
    postReview: function postReview(urlToLocation) {
      "use strict";

      gol.linkloader.show($("#facebook"));

      // Function that checks needed user permissions
      FB.login(function (response) {
        if (response.authResponse) {
          fbUid = response.authResponse.userID;
          fbAccessToken = response.authResponse.accessToken;

          //console.log("logged in facebook and app");

          var paramMap = {
            review: urlToLocation,
            access_token: fbAccessToken
          };
          FB.api("/me/" + gol.local.data.FBnameSpace + ":write", "post", paramMap, function (response) {
            if (!response || response.error) {
              //console.log("Error occured", response.error);
              gol.trackError(response.error, true);
            } else if (response !== null && response.id !== null) {
              //console.log("response=", response);

              gol.trackEvent("postAchievementToFacebook", "review");
              window.alert("Deine Aktivität wurde erfolgreich auf Facebook gepostet");
            } else {
              //console.log("Error occured, response not null, but id missing");
              gol.trackError("FB-Error occured, response not null, but id missing", true);
            }
            gol.linkloader.hide();
          });
        }
        /*else {
        	console.log("User cancelled login or did not fully authorize.");
        }*/
      }, {
        scope: fbPermissions
      });
      return false;
    }
  }
};