"use strict";

/**
* JS für StartPage, WelcomeBack, MetaBranches
* @author GoLocal GmbH & Co. KG
* @version 1.0
*/

(function glExtendStartMetas() {
  "use strict";

  gol.startmetas = {
    init: function init() {
      //console.log("Lists initialized");
      this.setHandlers();
    },
    setHandlers: function setHandlers() {
      $("a.showMoreCats", "#catsWrap").toggler(function () {
        $(this).text("Weniger Kategorien") // change text of anchor
        .parents("#catsWrap") // find folded elements and show them
        .find("li.folded").show();
      }, function () {
        $(this).text("Mehr Kategorien") // change text to previous state
        .parents("#catsWrap") // find folded elements and hide them
        .find("li.folded").hide();
      });
      gol.startmetas.addHandlerMoreLess();
    },
    addHandlerMoreLess: function addHandlerMoreLess() {
      $(".showLessOrMoreNeighbours").on("click", function () {
        //console.log("anchor clicked");
        $(this).off("click");
        var sUrl = $(this).attr("href");
        $.get(sUrl, function (data) {
          $("#citiesNearBy").replaceWith(data);
          gol.startmetas.addHandlerMoreLess();
          //console.log("Speichern erfolgreich");
        });
        return false;
      });
    },
    initPromotionsBox: function initPromotionsBox() {
      var promoBox;
      promoBox = document.getElementById("challengesForms");
      if (promoBox) {
        $("#challengesForms").validate({
          submitHandler: function submitHandler(form) {
            var options = {
              beforeSend: function beforeSend() {
                $("body").css("cursor", "progress");
                gol.trackEvent("acceptChallenge");
              },
              success: function success(returnValue) {
                if (returnValue.match(/ok/)) {
                  // Challenge angenommen / gestartet

                  /* Keine Browser-Notification mehr
                  var options = {
                  		title : "Neue Herausforderung angenommen",
                  		message : "Viel Erfolg",
                  		image : gol.local.data.imagePath + "de/notifications/challenges.png",
                  		timeout : 5000,
                  		tag : "challenges"
                  };
                  jQuery.fn.glNotifications.sendNotification(options);
                  */
                  gol.gen.reload();
                } else if (returnValue.match(/id\=\"?finishedWork\"?/)) {
                  // Challenge-Erfüllungskriterien sind bereits erfüllt

                  gol.dialog.open("Herausforderung geschafft!", "?modal=true&amp;height=200&amp;width=460&amp;reloadonclose=true", returnValue);
                } else {
                  gol.gen.alert(returnValue);
                }
              },
              error: function error(jqXHR, textStatus, errorThrown) {
                gol.gen.alert(errorThrown);
              },
              complete: function complete() {
                $("body").css("cursor", "");
              }
            };
            $(form).ajaxSubmit(options);
          }
        });
      }
    }
  };
})(jQuery);