"use strict";

/**
 * Gesponsorte Einträge in LocationLists
 *
 * @version 1.0.0 (28.01.2008)
 */
(function glSponsoredListings() {
  //
  // plugin definition
  //
  "use strict";

  var opts;

  // Begin fn.glSponsoredListings
  jQuery.fn.glSponsoredListings = function (options) {
    //console.log("$.glSponsoredListings() called");

    // build main options before element iteration
    opts = $.extend({}, $.fn.glSponsoredListings.defaults, options);
    return this.each(function () {
      var $this = $(this);

      // Support the metadata-plugin
      //var o = $.meta ? $.extend({}, opts, $this.data()) : opts;

      var animation = {
        init: function init($element) {
          //console.log("init(", $element, ") called");
          this.nCurrEntry = 1;
          this.nPrevEntry = 0;
          this.zIndex = 1;
          this.$mainWrapper = $element;
          this.$lis = $element.find(".listEntry");
          this.$infoText = $("div.sponsorInfoWrapper span.infoText");
          this.$switchSponsorUp = $("#switchSponsorUp");
          this.$switchSponsorDown = $("#switchSponsorDown");
          this.$switchButtons = $("span.btnSwitch", this.$mainWrapper);

          // Show first element
          //$(oFirstElement).slideDown("slow");
          this.setInfoText();
          // To be commented out if first entry should be visible at once
          //this.showNewEntry();

          // if more then one entry
          if (this.$lis.length > 1) {
            // set event handlers
            this.setHandlers();
            // start rotation
            this.startFlipInterval();
            this.$infoText.show();
            this.$switchButtons.show();
          } else {
            // hide switch-buttons and infoText
            this.$switchButtons.hide();
            //this.$infoText.hide();
          }
        },
        setHandlers: function setHandlers() {
          // Set handlers for viewed entries
          this.setEntryHandlers();

          // Set handlers for up- and down-switches
          this.setSwitchBtnHandlers();
        },
        setEntryHandlers: function setEntryHandlers() {
          var self = this;
          // set listEntry hover to stop rotating
          this.$lis.hover(function () {
            self.stopFlipInterval();
          }, function () {
            self.startFlipInterval();
          });
        },
        setSwitchBtnHandlers: function setSwitchBtnHandlers() {
          var self = this;
          // Set switch-handlers to highlight switches
          /*this.$switchButtons.hover(function(){
          	// Hover button
          	$(this).addClass("over");
          	self.stopFlipInterval();
          }, function(){
          	// Move out
          	$(this).removeClass("over");
          	self.startFlipInterval();
          }); */

          this.$switchSponsorUp.on("click", function () {
            self.flipUp();
          });
          this.$switchSponsorDown.on("click", function () {
            self.flipDown();
          });
        },
        flipUp: function flipUp() {
          if (this.booAnimationRunning) {
            return;
          }
          this.stopFlipInterval();
          this.flipSponsor();
        },
        flipDown: function flipDown() {
          if (this.booAnimationRunning) {
            return;
          }
          this.flipSponsor({
            direction: "back"
          });
        },
        startFlipInterval: function startFlipInterval() {
          //console.log("startFlipInterval() called");
          clearInterval(this.flipInterval);
          this.flipInterval = setInterval(this.flipSponsor, opts.flipInterval);
        },
        stopFlipInterval: function stopFlipInterval() {
          clearInterval(this.flipInterval);
        },
        flipSponsor: function flipSponsor(obj) {
          //console.log("flipSponsor(",obj,") called");

          var self = animation;
          if (obj && obj.direction === "back") {
            // set new entry ids for flipping back
            self.nPrevEntry = self.nCurrEntry;
            self.nCurrEntry--;
            self.nCurrEntry = self.nCurrEntry < 1 ? self.$lis.length : self.nCurrEntry;

            // do animations
            self.hidePrevEntry({
              direction: "back"
            });
            self.showNewEntry({
              direction: "back"
            });
          } else {
            // set new entry ids for flipping forward
            self.nPrevEntry = self.nCurrEntry;
            self.nCurrEntry++;
            self.nCurrEntry = self.nCurrEntry > self.$lis.length ? 1 : self.nCurrEntry;

            // do animations
            self.hidePrevEntry();
            self.showNewEntry();
          }
          self.setInfoText();
          //console.log("nCurrEntry=", self.nCurrEntry, "| nPrevEntry=", self.nPrevEntry);
        },
        setInfoText: function setInfoText() {
          this.$infoText.html("(" + this.nCurrEntry + " / " + this.$lis.length + ")");
        },
        getCurrentEntry: function getCurrentEntry() {
          return this.$lis[this.nCurrEntry - 1];
        },
        getPrevEntry: function getPrevEntry() {
          return this.$lis[this.nPrevEntry - 1];
        },
        hidePrevEntry: function hidePrevEntry(obj) {
          //console.log("hidePrevEntry(",obj,") called");
          //var self = this;

          // Variante 1 --> Fade Out
          //$(this.getCurrentEntry()).fadeOut("1000");

          // Variante 2 --> Slide Out
          if (obj && obj.direction === "back") {
            //console.log("hidePrevEntry(", this.getPrevEntry(), ").animate");
            // do animation for hiding current entry when 'back' was clicked
            $(this.getPrevEntry()).animate({
              top: "200px"
            }, {
              duration: 1000,
              easing: "easeInOutSine"
            });
          } else {
            // do normal hiding animation of current entry
            $(this.getPrevEntry()).animate({
              opacity: "0%"
            }, {
              duration: 1000,
              easing: "easeInOutSine"
            }, function () {
              // console.log("animation beendet");
            });
          }
        },
        showNewEntry: function showNewEntry(obj) {
          //console.log("showNextEntry() called");
          var self = this;

          // Variante 1 --> Fade In
          //$(this.getNextEntry()).fadeIn("1000");

          // Variante 2 --> Slide In
          this.booAnimationRunning = true;
          if (obj && obj.direction === "back") {
            // do animation for next entry when 'back' was clicked
            $(this.getCurrentEntry()).css({
              opacity: 0,
              top: "0px",
              zIndex: self.zIndex++
            }).animate({
              opacity: 1
            }, 1000, "easeInSine", function () {
              //console.log("animation fertig!");
              self.booAnimationRunning = false;
            });
          } else {
            // do normal forward-animation for next entry
            $(this.getCurrentEntry()).css({
              top: "200px",
              opacity: 1,
              zIndex: self.zIndex++
            }).animate({
              top: "0px"
            }, 1300, "easeInOutSine", function () {
              //console.log("animation fertig!");
              self.booAnimationRunning = false;
            });
          }
        }
      };
      // END animation object

      animation.init($this);

      //
      // Public functions
      //
      $.fn.glSponsoredListings.unload = function () {
        //console.log("$.fn.glSponsoredListings.unload() called");
        animation.stopFlipInterval();
      };
    });
  }; // End fn.glSponsoredListings

  //
  // plugin defaults
  //
  $.fn.glSponsoredListings.defaults = {
    flipInterval: 5000
  };

  //
  // private functions
  //

  //
  // end of closure
  //
})(jQuery);